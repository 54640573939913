import React, { useState, useEffect } from 'react';

//styles
import './dynamic-circle.scss';

//Card Data
import { DynamicCircleData } from './DynamicCircleData'

// images
const logoOne = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';

export default function DynamicCircle({ PageProgress, Progress }) {

    let [cardNum, setCardNum] = useState(0)

    const [atZero, setAtZero] = useState(true);

    // const cardDivision = 1 / DynamicCircleData.length
    const Progress0 = Progress[12]

    const Progress1 = Progress[13]
    const Progress2 = Progress[14]
    const Progress3 = Progress[15]
    const Progress5 = Progress[17]
    const Progress7 = Progress[19]
    const Progress11 = Progress[23]
    const Progress14 = Progress[25]


    useEffect(() => {

        if (PageProgress >= Progress1 && PageProgress < Progress2) {
            setCardNum(0)
            setAtZero(true)

        } else if (PageProgress >= Progress2 && PageProgress < Progress3) {
            setCardNum(1)
            setAtZero(false)

        } else if (PageProgress >= Progress3 && PageProgress < Progress5) {
            setCardNum(2)
            setAtZero(false)

        } else if (PageProgress >= Progress5 && PageProgress < Progress7) {
            setCardNum(3)
            setAtZero(false)
        } else if (PageProgress >= Progress7 && PageProgress < Progress11) {
            setCardNum(4)
            setAtZero(false)

        } else if (PageProgress >= Progress11) {
            setCardNum(5)
            setAtZero(false)

        }

        // eslint-disable-next-line
    }, [PageProgress, cardNum])


    return (
        <div className="dynamic-circle-main-container"
            style={{
                transform: PageProgress < Progress1 ? 'scale(0.3) translate(0vw)' :
                    PageProgress > Progress14 && 'scale(1) translate(100vw)',
                    opacity: PageProgress > Progress0 && '1'

            }}
        >
            {/* <p style={{ position: 'fixed', zIndex: '10', left: '1%', top: '15%', color: 'red' }}>{'(dynamicCircle)card division: ' + cardDivision}</p> */}

            <div className="main-circle-container" >
                <div className="circle-one" >
                    <div className="circle-two"  >
                        <div className="circle-three" >
                            <div className="circle-four" >
                                <img src={logoOne} alt="" className="logo-img"
                                    style={{
                                        opacity: PageProgress > Progress1 && '0'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------------- CARD SLIDER --------------------------------- */}
                <div className="cards-slider"
                    style={{
                        opacity: PageProgress > Progress1 && '1'
                    }}
                >

                    <div className="slides-div"
                        style={{
                            transform: PageProgress > Progress1 && 'scale(1)'
                        }}
                    >
                        {DynamicCircleData.map((item, i) => {
                            return (
                                <div className="card-div"
                                    key={i}
                                    style={{
                                        transform: i === 0 && atZero ? `scale(0.8)  rotate(30deg)` :
                                            i === 1 && atZero ? `scale(0.8) ` :
                                                i === 2 && atZero ? `scale(0.8) rotate(-30deg)` :
                                                    i === cardNum && !atZero ? `scale(1) ` :
                                                        i === cardNum - 1 && !atZero ? `scale(0.55)  rotate(30deg)` :
                                                            i === cardNum + 1 && !atZero ? `scale(0.65)  rotate(-35deg)` :
                                                                i < cardNum - 1 && !atZero ? `scale(0.55)  rotate(65deg)` :
                                                                    i > cardNum + 1 && !atZero && `scale(0.65)  rotate(-80deg)`,

                                        left: i === 0 && atZero ? `-5% ` :
                                            i === 1 && atZero ? `-15% ` :
                                                i === 2 && atZero ? `-5% ` :
                                                    i > 2 && atZero ? `100% ` :
                                                        i < cardNum - 1 && !atZero ? '15%' :
                                                            i === cardNum - 1 && !atZero ? '-7%' :
                                                                i === cardNum && !atZero ? '-20%' :
                                                                    i === cardNum + 1 && !atZero ? '0%' :
                                                                        i > cardNum + 1 && !atZero && '30%',

                                        top: i === 0 && atZero ? `0% ` :
                                            i === 1 && atZero ? `35% ` :
                                                i === 2 && atZero ? `75% ` :
                                                    i < cardNum - 1 && !atZero ? '-15%' :
                                                        i === cardNum - 1 && !atZero ? '5%' :
                                                            i === cardNum && !atZero ? '38%' :
                                                                i === cardNum + 1 && !atZero ? '80%' :
                                                                    i > cardNum + 1 && !atZero && '100%',

                                        opacity: i <= cardNum + 2 && atZero ? '1' :
                                            i === cardNum && !atZero ? '1' :
                                                i === cardNum - 1 && !atZero ? '1' :
                                                    i === cardNum + 1 && !atZero && '1',

                                        filter: i < cardNum && !atZero ? `blur(5px)` :
                                            i > cardNum && !atZero && `blur(5px)`

                                    }}
                                >
                                    <div className="img-div">
                                        <img src={item.imgURL} alt="" className="card-img" />
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>

            </div>
        </div >
    )
}
