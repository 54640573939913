import React, { useState } from "react";
import "./style.scss";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const UCLogo = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/uc-logo.png";
const UCOne = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/us-one.png";
const UCTwo = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/us-two.png";
const UCDialer = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/uc-dialer.png";

const UseCases = () => {
  const [isScale, setIsScale] = useState(false);
  return (
    <div className="use-cases-main">
      <div className="use-caes">
        <div className="diler-image">
          <img src={UCDialer} alt="" />
        </div>
        <div className="uc-left">
          <img src={UCLogo} alt="" className="uc-logo" />
          <div className="uc-heading">
            <h2>Dailer</h2>
          </div>
          <div className="uc-content">
            <h3 className="uc-subheading">Use Cases</h3>
            <div className="uc-para">
              <p className="uc-line">
                ➢ NAVIGATIONAL, INFORMATIONAL & TRANSACTIONAL BOTS
              </p>
              <p className="uc-line">➢ CALL CENTRE SOLUTIONS</p>
              <p className="uc-line">➢ GOVT. HELPLINES</p>
              <p className="uc-line">➢ GRIEVANCE MANAGEMENT AND HELPDESK</p>
              <p className="uc-line">➢ TELCO GRADE VOICE BROADCAST SOLUTIONS</p>
              <p className="uc-line">➢ COVID 19 BOTS</p>
              <p className="uc-line">➢ EMERGENCY & PSYCHOLOGICAL HELPLINES</p>
              <p className="uc-line">➢ COLLECTION MANAGEMENT</p>
            
            </div>
          </div>
        </div>
        <div className="uc-right">
          <div className="uc-right-images">
            <img
              src={UCOne}
              alt=""
              className="uc-two"
              style={{
                transform: isScale ? "scale(1)" : "scale(0.2)",
                zIndex: isScale ? "3" : "2",
              }}
            />
            <img
              src={UCTwo}
              alt=""
              className="uc-three"
              style={{
                transform: isScale ? "scale(0.2)" : "scale(1)",
                zIndex: isScale ? "2" : "3",
              }}
            />
           
          </div>
          <div className="uc-buttons">
            <div
              className="uc-dot-one"
              onClick={() => setIsScale(false)}
              style={{
                backgroundColor: !isScale && "#0078B7",
                width: isScale && "0.5vw",
                height: isScale && "0.5vw"
              }}
            ></div>
            <div className="uc-dot-two" onClick={() => setIsScale(true)}  style={{
                backgroundColor: isScale && "#0078B7",
                width: !isScale && "0.5vw",
                height: !isScale && "0.5vw"
              }}></div>
            <BsFillArrowRightCircleFill onClick={() => setIsScale(!isScale)} />
          </div>
        </div>
      </div>
    </div>
    // ?----------------------------
  );
};

export default UseCases;
