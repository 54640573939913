import React, { useMemo, useState } from 'react';

//style
import './our-training-process-comp.scss';

//images
const OurTrainingProcess = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/our-training-process.png';
const leftContentsOne = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1097.png';
const HeadingOne = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/heading-one.png';
const HeadingTwo = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/heading-two.png';
const rightContentOne = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1100.png';
const leftContentsTwo = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1101.png';
const rightContentTwo = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1103.png';

// import ourProcessHeading from 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Frame1095.png';
// import FrameBG from 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Frame-bg.png';


export default function OurTrainingProcessComp({ Progress, PageProgress }) {
    const Progress1 = Progress[6];
    const Progress2 = Progress[7];
    const Progress3 = Progress[8];
    const Progress4 = Progress[9];



    const [headingOpacityn, setHeadingOpacity] = useState(false)

    useMemo(() => {

        !headingOpacityn && PageProgress > Progress2 && setTimeout(() => {
            setHeadingOpacity(true)
        }, 2200);

        PageProgress < Progress2 && setHeadingOpacity(false)
        // eslint-disable-next-line
    }, [PageProgress])



    return (
        <div className="our-training-process-comp-main-container"
            style={{
                transform: PageProgress > Progress1 && PageProgress < Progress4 ? 'translateY(0)' :
                    PageProgress > Progress4 && 'translateY(-100vh)',
                opacity: PageProgress > Progress4 && '0'
            }}
        >
            <div className="our-training-process-comp-main">


                <img src={OurTrainingProcess} alt="" className="our-training-process-img"
                    style={{
                        transform: PageProgress > Progress2 && 'translate(32vw, -2vh) rotate(-90deg)',
                        animation: PageProgress > Progress2 && 'ourTraniningProcessKF 1.8s ease-in-out',
                        opacity: headingOpacityn && '0'
                    }}
                />

                <div className="our-training-process-heading"
                    style={{
                        transform: PageProgress > Progress2 && 'translate(34vw) scale(0.80)',
                        opacity: headingOpacityn && '0'

                    }}
                >
                    <img src={HeadingOne} alt="" className="our-training-process-heading-img" />
                </div>

                <div className="left-section"
                    style={{
                        opacity: PageProgress > Progress2 && '1'
                    }}
                >

                    <img src={leftContentsOne} alt="" className="left-content-frame-one"
                        style={{
                            transform: PageProgress > Progress2 && PageProgress < Progress3 ? 'translate(0) rotate(0deg)' :
                                PageProgress > Progress3 && 'translate(-100vw)'
                        }}
                    />
                    <img src={leftContentsTwo} alt="" className="left-content-frame-two"
                        style={{
                            transform: PageProgress > Progress3 && 'translateY(0vh)'
                        }}
                    />

                </div>

                <div className="right-section"
                    style={{
                        // transform: PageProgress > Progress2 && 'translate(34vw) scale(0.80)',
                        opacity: headingOpacityn && '1'

                    }}
                >
                    <div className="our-training-process-heading-two"
                        style={{
                            transform: headingOpacityn && PageProgress < Progress3 ? 'translateY(0)' :
                                PageProgress > Progress3 && 'translateY(-40vh)'

                        }}
                    >
                        <img src={HeadingTwo} alt="" className="our-training-process-heading-two-img" />
                    </div>

                    <div className="right-contents-div">
                        <img src={rightContentOne} alt="" className="right-content-one-img"
                            style={{
                                clip: headingOpacityn && 'rect(0px, 50vw, 100vh, 0px)',
                                transform: PageProgress > Progress3 && 'translate(-100vw)'

                            }}
                        />

                        <img src={rightContentTwo} alt="" className="right-content-two-img"
                            style={{
                                opacity: PageProgress > Progress3 && '1'

                            }}
                        />


                    </div>

                </div>



            </div>
        </div >
    )
}
