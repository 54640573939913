
export const logoCircle = [
    {
        color: '#F29CF6',
    },
    {
        color: '#7E37CB',
    },
    {
        color: '#54C0EB',
    },
    {
        color: '#AFD371',
    },
    {
        color: '#F8E75F',
    },
    {
        color: '#EA7B5B',
    },
    {
        color: '#CF6269',
    },
    {
        color: '#8F4699',
    },
]
