import React from 'react';

//styles
import './advisory-services.scss';

//images
const LogoImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';


export default function AdvisoryServices({ Progress, PageProgress }) {

    const progress1 = Progress[30]
    const progress2 = Progress[31]
    // const progress3 = Progress[32]

    const dataOne = [
        {
            heading1: 'Ideation/',
            heading2: ' Conceptualization',
            paragraph: `Generating creative ideas for enhancing B2B customer service lines. Exploring innovative approaches to deliver effective customer support.`
        },
        {
            heading1: 'Market Research &',
            heading2: 'Competition Mapping',
            paragraph: `Generating creative ideas for enhancing B2B customer service lines. Exploring innovative approaches to deliver effective customer support.`
        },
        {
            heading1: 'High-Level',
            heading2: 'Framework',
            paragraph: `Outlining primary objectives and key strategies for customer service. Defining initial implementation plans within the B2B company.`
        }
    ]
    const dataTwo = [
        {
            heading1: 'Government Norms & ',
            heading2: 'Regulations Mapping',
            paragraph: `Researching and ensuring compliance with relevant B2B customer service regulations. Preventing legal issues and maintaining ethical practices.`
        },
        {
            heading1: 'Types of Support',
            heading2: ' Touch Points',
            paragraph: `Determining various customer support touch points and required resources. Emphasizing roles of HR, Training Quality, and Operations for collaborative efforts.`
        },
        {
            heading1: '',
            heading2: 'ICT - Plan Framework',
            paragraph: `Creating a plan for utilizing ICT in customer service. Setting up communication platforms and systems to streamline interactions.`
        }
    ]


    return (
        <div className="advisory-services-main-container"
            style={{
                // transform: PageProgress > progress1 && PageProgress < progress3 ? 'translateY(0vh)' :
                //     PageProgress > progress3 && 'translateY(-100vh)'
                transform: PageProgress > progress1 && 'translateY(0vh)'

            }}
        >
            <div className="advisory-services-main">
                <img src={LogoImg} alt="" className="logo-div" />

                <div className="advisory-services-heading"
                    style={{
                        transform: PageProgress > progress2 && 'translate(35vw)',
                        textAlign: PageProgress > progress2 && 'center',
                    }}
                >
                    Our <br />
                    <span className="span1"
                        style={{
                            fontWeight: PageProgress > progress2 && '600'
                        }}
                    >Advisory Services</span>
                </div>
              
                <div className="content-container">

                    <div className="upper-div">
                        <div className="inner-div"
                            style={{
                                transform: PageProgress > progress2 && 'translate(-100vw)'
                            }}
                        >
                            {dataOne.map((item, i) => {
                                return (
                                    <div className="card-div" key={i}>
                                        <span className="heading-span"
                                            style={{
                                                display: item.heading1 === '' && item.heading2 === '' && 'none'
                                            }}
                                        >
                                            <span className="span1">{item.heading1}</span>
                                            <strong>{item.heading2}</strong>
                                        </span>
                                        <p>{item.paragraph}</p>

                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    <div className="lower-div">

                        <div className="inner-div"
                            style={{
                                transform: PageProgress > progress2 && 'translate(-100vw)'
                            }}
                        >
                            {dataTwo.map((item, i) => {
                                return (
                                    <div className="card-div" key={i}>
                                        <span className="heading-span"
                                            style={{
                                                display: item.heading1 === '' && item.heading2 === '' && 'none'
                                            }}
                                        >
                                            <span className="span1">{item.heading1}</span>
                                            <strong>{item.heading2}</strong>
                                        </span>
                                        <p>{item.paragraph}</p>
                                        <img src={item.imgURL} alt="" />

                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>

                {/* ---------------------------------------------------------------- */}

                <div className="scope-of-work-card"
                    style={{
                        opacity: PageProgress < progress2 && "0",
                    }}
                >

                    <div className="shadow-div"
                        style={{
                            transform: PageProgress < progress2 && "translate(20vw, -100vh)",
                        }}
                    >
                        <div className="border-div" />
                    </div>

                    <div className="inner-div">

                        <div className="card-heading">
                            <strong className="span1">SOW</strong><br />
                            <span>(Scope Of Work)</span>
                        </div>
                        <p>Defining tasks, responsibilities, and deliverables for the customer service initiative. <br /> <br />
                            Clarifying the steps, expected outcomes, and goals of establishing customer service lines in the B2B company.</p>

                    </div>

                </div>

            </div>

        </div>
    )
}
