import React from 'react';

//styles
import './client-legacy-comp.scss';

//images
const clientLegacy = 'https://rentblob.blob.core.windows.net/sample-sales/home/client-legacy.png';
const Slide1 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1000.png';
const Slide2 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame999.png';
const Slide3 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1002.png';



export default function ClientLegacyComp({ Progress, PageProgress }) {
    const progress1 = Progress[3]
    const progress2 = Progress[4]
    const progress3 = Progress[5]

    return (
        <div className="client-legacy-comp-main-container"
            style={{
                transform: PageProgress > progress1 && PageProgress < progress3 ? 'translateY(0vh)' :
                    PageProgress > progress3 && 'translateY(-100vh)',
                opacity: PageProgress > progress1 && PageProgress < progress2 ? '1' :
                    PageProgress > progress2 && '0'

            }}
        >
            <div className="client-legacy-comp-main">

                <img src={clientLegacy} alt="" className="client-legacy-img"
                    style={{
                        transform: PageProgress > progress1 && PageProgress < progress2 && 'translate(0vw)'
                    }}
                />
                <div className="slider-div"
                    style={{
                        transform: PageProgress > progress2 && 'rotate(-35deg) translate(140vw)'
                    }}
                >

                    <div className="slides-top"
                        style={{
                            transform: PageProgress < progress1 && 'translate(-80vw)'
                        }}
                    >
                        <img src={Slide1} alt="" className="slide-img" />
                    </div>
                    <div className="slides-middle"
                        style={{
                            transform: PageProgress < progress1 && 'translate(80vw)'
                        }}
                    >
                        <img src={Slide2} alt="" className="slide-img" />
                    </div>
                    <div className="slides-bottom"
                        style={{
                            transform: PageProgress < progress1 && 'translate(-80vw)'
                        }}
                    >
                        <img src={Slide3} alt="" className="slide-img" />
                    </div>
                </div>

            </div>


        </div>
    )
}
