import React, { useState, useEffect } from 'react';

//styles
import './years-of-experience-comp.scss';

//images
const coloredGroupCircles = 'https://rentblob.blob.core.windows.net/sample-sales/home/colored-group-circles.png';
const MaskImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/mask-img.png';
// const maskText = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/home/hundred-years-text.png';
const maskText = 'https://i.ibb.co/mJZmDdq/hundred-years-text.png';
const yearsOfExperienceHeading = 'https://rentblob.blob.core.windows.net/sample-sales/home/years-of-experience-heading.png';
const technotaskLogo = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';
const slidesImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/slides-img.png';


export default function YearsOfExperienceComp({ Progress, PageProgress }) {

    const progress1 = Progress[4]
    const progress2 = Progress[5]
    const progress3 = Progress[6]


    const [maskRotation, setMaskRotation] = useState(false)

    useEffect(() => {

        !maskRotation ? setTimeout(() => {
            setMaskRotation(true)
        }, 8000) :
            maskRotation && setTimeout(() => {
                setMaskRotation(false)
            }, 8000)

    }, [maskRotation])


    return (
        <div className="years-of-experience-comp-main-container"
            style={{
                opacity: PageProgress > progress1 && PageProgress < progress2 ? '1' :
                    PageProgress > progress2 && '0',
                zIndex: PageProgress > progress3 && '-1'

            }}
        >
            <div className="years-of-experience-comp-main">
                <img src={coloredGroupCircles} alt="" className="colored-group-circle-left"
                    style={{
                        transform: PageProgress > progress1 && PageProgress < progress2 ? 'translate(0)' :
                            PageProgress > progress2 && 'translateY(-100vh)',

                    }}
                />
                <img src={coloredGroupCircles} alt="" className="colored-group-circle-right"
                    style={{
                        transform: PageProgress > progress1 && PageProgress < progress2 ? 'translate(0)' :
                            PageProgress > progress2 && 'translateY(-100vh)',

                    }}
                />

                <div className="heading-div">
                    <img src={technotaskLogo} alt="" className="logo-img"
                        style={{
                            transform: PageProgress > progress1 && PageProgress < progress2 ? 'translate(0)' :
                                PageProgress > progress2 && 'translateY(-100vh)',
                        }}
                    />
                    <img src={yearsOfExperienceHeading} alt="" className="years-of-experience-heading"
                        style={{
                            transform: PageProgress > progress1 && PageProgress < progress2 ? 'translate(0)' :
                                PageProgress > progress2 && 'translateY(-100vh)',
                        }}
                    />
                    <div className="mask-div"
                        style={{
                            maskImage: `url(${maskText})`,
                            WebkitMaskImage: `url(${maskText})`,
                            opacity: PageProgress > progress1 && '1',
                            transform: PageProgress > progress2 && 'translateY(-100vh)',
                        }}
                    >
                        <img src={MaskImg} alt="" className='mask-bg-img'
                            style={{
                                transform: maskRotation && 'rotate(-60deg)'
                            }}
                        />

                    </div>
                </div>

                <div className="slider-div"
                    style={{
                        transform: PageProgress > progress1 && PageProgress < progress2 ? 'translate(0)' :
                            PageProgress > progress2 && 'translateY(100vh)',

                    }}
                >
                    <div className="slides-div">
                        <img src={slidesImg} alt="" className="slide-img"
                            style={{
                                transform: maskRotation && 'translate(-70vw)',
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}
