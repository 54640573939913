import React, { useState, useEffect } from 'react';

//styles
import './hr-suit-comp.scss';

//images
const ellipse1 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse1.png';
const ellipse2 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse2.png';
const ellipse3 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse3.png';
const ellipse4 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse4.png';
const ellipse5 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse5.png';
const ellipse6 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse6.png';
const ellipse7 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse7.png';
const ellipse8 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Ellipse8.png';

const frame1 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Frame1083.png';
const frame2 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Frame1084.png';
const frame3 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Frame1085.png';
const frame4 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Frame1086.png';
const frame5 = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/Frame1087.png';



export default function HRSuitComp({ Progress, PageProgress }) {
    const Progress1 = Progress[4];
    const Progress3 = Progress[5];
    const Progress4 = Progress[6];



    const [circleExpand, setCircleExpand] = useState(false);
    const [readmoreBtn, setReadmoreBtn] = useState(false)
    const [circleExpandTwo, setCircleExpandTwo] = useState(false)
    const [hiddenLayerIndex, setHiddenLayerIndex] = useState(false)

    useEffect(() => {
        !circleExpand && PageProgress > Progress1 && setTimeout(() => {
            setCircleExpand(true)
        }, 1200);

        PageProgress < Progress1 && setCircleExpand(false)
// eslint-disable-next-line
    }, [PageProgress])

    useEffect(() => {
        circleExpand && setTimeout(() => {
            setCircleExpandTwo(true)
        }, 2000);
        PageProgress < Progress1 && setCircleExpandTwo(false)
        // eslint-disable-next-line
    }, [circleExpand])

    useEffect(() => {
        circleExpandTwo && setTimeout(() => {
            setHiddenLayerIndex(true)
        }, 1000);
        PageProgress < Progress1 && setHiddenLayerIndex(false)
        // eslint-disable-next-line
    }, [circleExpandTwo])




    return (
        <div className="hr-suit-comp-main-container"
            style={{
                transform: PageProgress > Progress1 && PageProgress < Progress4 ? 'translate(0)' :
                    PageProgress > Progress4 && 'translateY(-100vh)'
            }}
        >
            <p style={{ position: 'fixed', top: '10%', color: 'red', zIndex: '20' }}>{circleExpandTwo.toString()}</p>

            <div className="hr-suit-comp-main">

                <div className="grouped-cards-slides">
                    <div className="inner-div"
                        style={{
                            transform: PageProgress > Progress3 && 'translate(-50%)'
                        }}
                    >

                        <div className="card1-div"
                            style={{
                                borderRadius: PageProgress > Progress1 && PageProgress < Progress4 && '0vw 0 0vw 0'

                            }}
                        >
                            <img src={frame2} alt="" className="frame-img" />
                            <div className="content-div">
                                <div className="content-section">
                                    <p
                                        style={{
                                            color: '#000',
                                            fontWeight: '600'
                                        }}
                                    >Once the requirement is understood and JD is agreed upon, then the hiring/sourcing team starts to look for the right candidates for the job, the sources that the team explores are:</p>
                                    <p
                                        style={{
                                            lineHeight: '1.6vw'
                                        }}
                                    >

                                        • Employee referral <br />
                                        • Recruitment Consultants (vendor) <br />
                                        • Job Portals (Naukri, Indeed, Apna app  etc.), mass mailing, job postings. <br />
                                        • Social Media Websites/App (LinkedIn, Facebook, Instagram) <br />
                                        • College Campus Recruitment drives <br />
                                        • Digital Newspaper Advertisements <br />
                                        • Local WhatsApp groups <br />
                                        • Freelancer Consultants (FOC resources)

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card2-div"
                            style={{
                                borderRadius: PageProgress > Progress1 && PageProgress < Progress4 && '0vw 0 0vw 0'

                            }}
                        >

                            <img src={frame1} alt="" className="frame-img" />
                            <div className="content-div">
                                <div className="content-section">
                                    <p
                                        style={{
                                            color: '#000',
                                            fontWeight: '600'
                                        }}
                                    >Requisition (MRF) is raised by the concerned department viz.
                                        Ops/Training/Quality etc.</p>
                                    <p
                                        style={{
                                            color: '#000',
                                        }}
                                    >The same is raised by the Process Owner, approved by HOD and then by the CBO. If it is a new position then the JD is prepared and cost analysis is done as per the market standards, a primary approval on price point is procured by the HR Head, CFO and CEO (for support Staff).
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card3-div"
                            style={{
                                borderRadius: PageProgress > Progress1 && PageProgress < Progress4 && '0vw 0 0vw 0'

                            }}
                        >
                            <img src={frame3} alt="" className="frame-img" />
                            <div className="content-div">
                                <section
                                    className='content-section'
                                    style={{
                                        transform: readmoreBtn && 'translateY(-30vh)'
                                    }}
                                >
                                    <p>Candidates walking in for an interview are asked to scan the QR code placed at the entry point of the Recruitment area.</p>
                                    <p>• The QR code takes the candidate to a form embedded in the HRMS portal, where the candidate fills his/her his details.</p>
                                    <p>• The candidates are then called for a First round Interview with the HR (Recruiter) who assesses the candidates as per the criteria given in the MSR.</p>
                                    <p>• The HR shortlisted candidates are given tests, specific to processes.</p>
                                    <p>• After a pool of 5-6 candidates is created who have been shortlisted by the HR and who have cleared the test; an assigned panel member from Operations and Training is instructed to assess the candidates.</p>
                                    <p>• The candidates who are not considered are advised to contact after the expiry of cooling off period, selected candidates are given an orientation by the recruiter, their offered compensations are explained to them. A one pager document explaining the documents that are required at the time of joining is handed over to them. They are called on a particular day and time for their joining.</p>

                                </section>

                            </div>
                            <div className="btn-div"

                                onClick={() => { setReadmoreBtn(p => !p) }}>
                                {
                                    readmoreBtn ? ' less' : 'more'
                                }
                            </div>
                        </div>
                        <div className="card4-div"
                            style={{
                                borderRadius: PageProgress > Progress1 && PageProgress < Progress4 && '0vw 0 0vw 0'

                            }}
                        >
                            <img src={frame4} alt="" className="frame-img" />
                            <div className="content-div">
                                <div className="content-section">
                                    <p
                                        style={{
                                            color: '#000',
                                            fontWeight: '600',
                                            lineHeight: '1.6vw'
                                        }}
                                    >
                                        On-boarding Formalities & Induction Sign OFT <br />

                                        • Physical Form Submission <br />

                                        • Information Security Policy <br />

                                        • Employee Undertaking <br />

                                        • Background Verification Form, <br />
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="card5-div"
                            style={{
                                borderRadius: PageProgress > Progress1 && PageProgress < Progress4 && '0vw 0 0vw 0'

                            }}
                        >
                            <img src={frame5} alt="" className="frame-img" />
                            <div className="content-div">
                                <div className="content-section">
                                    <p>
                                        As an organization, TTBS does not want any of their employees to leave, but if the situation arises that an employee wishes to part ways for any reason then there is a process for such an exit.
                                    </p>
                                    <p>
                                        Separation can be primarily in the following categories:
                                        Resignation, Termination under ZTP, Job Absconding, Asked To Leave
                                    </p>
                                </div>
                            </div>
                        </div >


                    </div >


                </div >

                <div className="hr-suit-heading-main"
                    style={{
                        zIndex: hiddenLayerIndex && '-1'

                    }}
                >

                    <div className="circle-group-div"
                        style={{
                            transform: circleExpandTwo && 'translateY(-100vh) scale(0.6)'

                        }}
                    >

                        <img src={ellipse1} alt="" className="circle1-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />
                        <img src={ellipse2} alt="" className="circle2-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />
                        <img src={ellipse3} alt="" className="circle3-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />
                        <img src={ellipse4} alt="" className="circle4-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />
                        <img src={ellipse5} alt="" className="circle5-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />
                        <img src={ellipse6} alt="" className="circle6-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />
                        <img src={ellipse7} alt="" className="circle7-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />
                        <img src={ellipse8} alt="" className="circle8-img"
                            style={{
                                transform: circleExpand && 'translate(0) scale(1)'
                            }}
                        />

                    </div>
                    <div className="top-layer"
                        style={{
                            transform: circleExpandTwo && 'translateY(100vh)',

                        }}
                    />

                    <div className="hr-suit-text"
                        style={{
                            transform: circleExpand && !circleExpandTwo ? 'scale(1)' :
                                circleExpandTwo && 'translateY(-100vh) scale(1)',
                            opacity: circleExpand && '1',

                        }}
                    >HR SUIT</div>



                </div>

            </div >
        </div >
    )
}
