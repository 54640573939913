import React from 'react';

//styles
import './presence-outreach.scss';

//images
const CompaniesLogoFrame = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1019.png';
const logoAndHeadingImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1020.png';

export default function PresenceOutreach({ PageProgress, Progress }) {

    const Progress1 = Progress[10]
    const Progress2 = Progress[11]
    const Progress3 = Progress[12]



    return (
        <div className="presence-outreach-main-container"
            style={{
                transform: PageProgress > Progress1 && PageProgress < Progress3 ? 'translateY(0)' :
                    PageProgress > Progress3 && 'translateY(-0vh)',
                opacity: PageProgress < Progress1 ? '0' :
                    PageProgress > Progress3 && '0',
                zIndex: PageProgress > Progress3 && '-1'

            }}
        >
            <div className="presence-outreach-main">
                <img src={logoAndHeadingImg} alt="" className="logo-and-heading-img"
                    style={{
                        transform: PageProgress > Progress3 && 'translateY(-100vh)'
                    }}
                />

                <div className="bottom-slider"
                    style={{
                        transform: PageProgress > Progress3 && 'translateY(-100vh)'
                    }}
                >
                    <img src={CompaniesLogoFrame} alt="" className="companies-logo-frame-img"
                        style={{
                            transform: PageProgress > Progress2 && 'translateY(-23.8vw)'
                        }}
                    />

                </div>
            </div>
        </div>
    )
}
