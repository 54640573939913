import React, { useState, useEffect } from 'react';

//styles
import './home-page.scss';

//libraries
import Stickyroll from "@stickyroll/react/stickyroll";

//components
import KeyFeature from './Our-Offering-Comp/our-offering';
import OurCofoundersComp from './Our-Cofounders-Comp/our-cofounders-comp';
import WhyUsComp from './Why-Us-Comp/why-us-comp';
import ClientLegacyComp from './Client-Legacy-Comp/client-legacy-comp';
import YearsOfExperienceComp from './Years-Of-Experience-Comp/years-of-experience-comp';
import JourneyComp from './Journey-Comp/journey-comp';
import ClientHandledComp from './Client-Handled-Comp/client-handled-comp';
import GlobeComp from './Globe-Comp/globe-comp';
import UpperContent from './Upper-Contents-Comp/upper-content';
import PresenceOutreach from './Presence-Ovetreach/presence-outreach';
import CustomerSupport from './Customer-Support/customer-support';
import KeyFeaturesComp from './Key-Features-Comp/key-features-comp';
import OurNewVerticle from './Our-New-Verticle-Comp/our-new-verticle';
import AdvisoryServices from './Advisory-Services/advisory-services';

export default function HomePage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [pageProgress, setPageProgress] = useState(0)


    const progress = [
        0.03,
        0.06,
        0.09,
        0.12,
        0.15,
        0.18,
        0.21,
        0.24,
        0.27,
        0.3,
        0.33,
        0.36,
        0.39,
        0.42,
        0.45,
        0.48,
        0.51,
        0.54,
        0.57,
        0.6,
        0.63,
        0.66,
        0.69,
        0.72,
        0.75,
        0.78,
        0.81,
        0.84,
        0.87,
        0.90,
        0.94,
        0.98,

    ]

    return (
        <div className="home-page-main-container">
            {/* <p style={{ position: 'fixed', top: '0', color: 'red', zIndex: '10' }}>{pageProgress}</p> */}

            <Stickyroll
                pages={1}
                factor={50}
                onProgress={p => setPageProgress(p)}
            >
                <div className="home-page-main-section">
                    <KeyFeature
                        Progress={progress}
                        PageProgress={pageProgress}
                    />
                    <OurCofoundersComp Progress={progress} PageProgress={pageProgress} />
                    <WhyUsComp Progress={progress} PageProgress={pageProgress} />
                    <ClientLegacyComp Progress={progress} PageProgress={pageProgress} />
                    <YearsOfExperienceComp Progress={progress} PageProgress={pageProgress} />
                    <JourneyComp Progress={progress} PageProgress={pageProgress} />
                    <ClientHandledComp Progress={progress} PageProgress={pageProgress} />
                    <GlobeComp Progress={progress} PageProgress={pageProgress} />
                    <UpperContent Progress={progress} PageProgress={pageProgress} />
                    <PresenceOutreach Progress={progress} PageProgress={pageProgress} />

                    <CustomerSupport Progress={progress} PageProgress={pageProgress} />
                    <KeyFeaturesComp Progress={progress} PageProgress={pageProgress} />
                    <OurNewVerticle Progress={progress} PageProgress={pageProgress}/>
                    <AdvisoryServices Progress={progress} PageProgress={pageProgress}  />


                </div>
            </Stickyroll>

        </div>
    )
}
