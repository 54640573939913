import React, { useState } from 'react';

//style
import './other-features-comp.scss';

//components
import UseCases from '../../../components/UseCases';
import Compliance from '../../../components/Compliance/Compliance';
import CRMComp from '../../../components/CRM';
import Analytics from '../../../components/Analytics';
import EcoSystem from '../../../components/EcoSystem/EcoSystem';

//imgs
const crm = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/CRM.png';
const dailer = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Dailer.png';
const ecosystemPartners = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Ecosystem-partners.png';
const itComplaince = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/IT-complaince.png';
const speechAnalytics = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Speech-Analytics.png';
const ballImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1152.png';
const headingImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1153.png';


export default function OtherFeaturesComp({ Progress, PageProgress }) {
    const Progress1 = Progress[13];
    const Progress2 = Progress[14];

    const [cardNumb, setCardNumb] = useState(0)
    const [toggle, setToggle] = useState(false)

    return (
        <div className="other-features-comp-main-container"
            style={{
                opacity: PageProgress > Progress1 && '1'
            }}
        >
            <div className="other-features-comp-main">
                <img src={headingImg} alt="" className="heading-img"
                    style={{
                        transform: PageProgress < Progress1 && 'translateY(-50vh)'
                    }}
                />

                <img src={ballImg} alt="" className="ball1-img"
                    style={{
                        opacity: PageProgress < Progress1 && '0',
                        transform: PageProgress < Progress1 && 'translateY(160vh)'

                    }}
                />
                <img src={ballImg} alt="" className="ball2-img"
                    style={{
                        opacity: PageProgress < Progress1 && '0',
                        transform: PageProgress < Progress1 && 'translateY(120vh)'

                    }}
                />
                <img src={ballImg} alt="" className="ball3-img"
                    style={{
                        opacity: PageProgress < Progress1 && '0',
                        transform: PageProgress < Progress1 && 'translateY(80vh)'

                    }}
                />



                <div className="scroller-section"
                    style={{
                        transform: PageProgress > Progress2 && 'translate(-30vw)'
                    }}
                >
                    <div className="dailer-sec"
                        style={{
                            transform: PageProgress < Progress1 && 'translateY(120vh)'
                        }}
                    >

                        <p className="more-details-btn"
                            onClick={() => {
                                setToggle(true);
                                setCardNumb(1)
                            }}
                        >Click For More Details</p>
                        <img src={dailer} alt="" className="dailer" />

                    </div>

                    <div className="it-complaince-sec"
                        style={{
                            transform: PageProgress < Progress1 && 'translateY(100vh)'
                        }}
                    >
                        <p className="more-details-btn"
                            onClick={() => {
                                setToggle(true)
                                setCardNumb(2)

                            }}
                        >Click For More Details</p>

                        <img src={itComplaince} alt="" className="it-complaince" />
                    </div>

                    <div className="crm-sec"
                        style={{
                            transform: PageProgress < Progress1 && 'translateY(80vh)'
                        }}
                    >
                        <p className="more-details-btn"
                            onClick={() => {
                                setToggle(true)
                                setCardNumb(3)

                            }}
                        >Click For More Details</p>

                        <img src={crm} alt="" className="crm" />
                    </div>

                    <div className="ecosystem-partners-sec"
                        style={{
                            transform: PageProgress < Progress1 && 'translateY(120vh)'
                        }}
                    >
                        <p className="more-details-btn"
                            onClick={() => {
                                setToggle(true)
                                setCardNumb(4)

                            }}
                        >Click For More Details</p>

                        <img src={ecosystemPartners} alt="" className="ecosystem-partners" />

                    </div>
                    <div className="speech-analytics-sec"
                        style={{
                            transform: PageProgress < Progress1 && 'translateY(100vh)'
                        }}
                    >
                        <p className="more-details-btn"
                            onClick={() => {
                                setToggle(true)
                                setCardNumb(5)

                            }}
                        >Click For More Details</p>

                        <img src={speechAnalytics} alt="" className="speech-analytics" />
                    </div>

                    {/* ----------------------------Dummy----------------------- */}
                    <div className="crm-sec"
                        style={{
                            transform: PageProgress < Progress1 && 'translateY(80vh)'
                        }}
                    >
                        <p className="more-details-btn"
                            onClick={() => { setToggle(true) }}
                        >Click For More Details</p>

                        <img src={crm} alt="" className="crm" />
                    </div>
                    {/* -------------------------------------------------------- */}

                </div>

                <div className="hidden-div"
                    style={{
                        display: !toggle && 'none'
                    }}
                >
                    <div className="inner-section" >
                        <button className="close-btn"
                            onClick={() => { setToggle(false) }}
                        >
                            close
                        </button>

                        {
                            cardNumb === 1 ? <UseCases /> :
                                cardNumb === 2 ? <Compliance /> :
                                    cardNumb === 3 ? <CRMComp /> :
                                        cardNumb === 4 ? <EcoSystem /> :
                                            cardNumb === 5 && <Analytics />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
