import React from 'react';

//style
import './globe-comp.scss';

//img
const Globe1 = 'https://rentblob.blob.core.windows.net/sample-sales/home/globe1.png';
const Globe2 = 'https://rentblob.blob.core.windows.net/sample-sales/home/globe2.png';
const Globe4 = 'https://rentblob.blob.core.windows.net/sample-sales/home/globe4.png';


export default function GlobeComp({ PageProgress, Progress }) {

    const Progress1 = Progress[7]
    const Progress2 = Progress[8]
    const Progress3 = Progress[9]
    const Progress4 = Progress[10]

    return (
        <div className="globe-comp-main-container"
            style={{
                bottom: PageProgress > Progress1 && '0%',
                opacity: PageProgress > Progress4 && '0',
                transform: PageProgress > Progress4 && 'translateY(-100vh)',


            }}
        >
            <div className="globle-div"
                style={{
                    transform: PageProgress > Progress3 && 'translateY(-100vh)',
                }}
            >

                <img src={Globe1} alt="" className="globe1-img" />
                <img src={Globe2} alt="" className="globe2-img"
                    style={{
                        opacity: PageProgress > Progress2 && '1'

                    }}
                />
            </div>

            <div className="map-and-cities"
                style={{
                    opacity: PageProgress > Progress3 && '1',

                }}
            >
                <img src={Globe4} alt="" className="globe3-img" />

            </div>


        </div>
    )
}
