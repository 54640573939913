import React, { useEffect, useState } from 'react';

//styles
import './solution-comp.scss';

// images
const bgImages = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1060.png';
const frame0 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/frame00.png';
const frame1 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/frame01.png';
const frame2 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/frame02.png';
const frame3 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/frame03.png';
const frame4 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/frame04.png';
const frame5 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/frame05.png';


export default function SolutionComp({ Progress, PageProgress }) {
    const Progress1 = Progress[1];
    const Progress2 = Progress[2];
    const Progress3 = Progress[3];


    const [cardExpand, setCardExpand] = useState(false)

    useEffect(() => {

        if (PageProgress > Progress2 && !cardExpand) {
            setTimeout(() => {
                setCardExpand(true)

            }, 2000)
        }
        else if (PageProgress < Progress2 && cardExpand) {
            setCardExpand(false)

        }
        // eslint-disable-next-line
    }, [PageProgress])

    return (
        <div className="solution-comp-main-container"
            style={{
                opacity: PageProgress > Progress1 && PageProgress < Progress3 ? '1' :
                    PageProgress > Progress3 && '0',
                transform: PageProgress > Progress3 && 'translateY(-100vh)'

            }}
        >
            <div className="solution-comp-main">

                <img src={frame0} alt="" className="frame-zero"
                    style={{
                        transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'scale(1)' :
                            PageProgress > Progress2 && 'translateY(-100vh) scale(1)',
                        opacity: PageProgress > Progress2 && '0'
                    }}
                />

                <img src={bgImages} alt="" className="bg-imgs"
                    style={{
                        opacity: PageProgress > Progress2 && '1',
                        transform: PageProgress > Progress2 && 'scale(1)'
                    }}
                />

                <img src={frame1} alt="" className="frame-one"
                    style={{
                        opacity: cardExpand && '1',
                        transform: cardExpand && 'translate(0)'
                    }}
                />
                <img src={frame2} alt="" className="frame-two"
                    style={{
                        opacity: cardExpand && '1',
                        transform: cardExpand && 'translate(0)'
                    }}
                />
                <img src={frame3} alt="" className="frame-three"
                    style={{
                        opacity: PageProgress > Progress2 && '1'
                    }}
                />
                <img src={frame4} alt="" className="frame-four"
                    style={{
                        opacity: cardExpand && '1',
                        transform: cardExpand && 'translate(0)'
                    }}
                />
                <img src={frame5} alt="" className="frame-five"
                    style={{
                        opacity: cardExpand && '1',
                        transform: cardExpand && 'translate(0)'
                    }}
                />




            </div>
        </div>
    )
}
