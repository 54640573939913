
//images
const ecomCapabilities = 'https://rentblob.blob.core.windows.net/sample-sales/home/ecom-capabilities.png';
const evCapabilities = 'https://rentblob.blob.core.windows.net/sample-sales/home/ev-capabilities.png';
const fintechCapabilities = 'https://rentblob.blob.core.windows.net/sample-sales/home/fintech-capabilities.png';
const technotaskCapabilities = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-capabilities.png';
const healthCapabilities = 'https://rentblob.blob.core.windows.net/sample-sales/home/health-capabilities.png';


export const DynamicCircleData = [
    {
        name: '',
        imgURL: healthCapabilities
    },
    {
        name: '',
        imgURL: ecomCapabilities
    },
    {
        name: '',
        imgURL: evCapabilities
    },
    {
        name: '',
        imgURL: fintechCapabilities
    },
    {
        name: '',
        imgURL: technotaskCapabilities
    },
    {
        name: '',
        imgURL: healthCapabilities
    },
    {
        name: '',
        imgURL: ecomCapabilities
    },


]