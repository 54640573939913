import { useState, useEffect } from "react";

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

//styles
import './App.scss';

//pages
import HomePage from './pages/Home-Page/home-page';
import HowItWorks from "./pages/How-It-Works/how-it-works";

//components
import PhoneRotationComp from "./components/Phone-Rotation-Comp/phone-rotation-comp";

function App() {
  // eslint-disable-next-line
  const [pathName, setPathName] = useState();

  useEffect(() => {
    setPathName(window.location.pathname);
  }, []);

  let mediaQuery = window.matchMedia(`(max-width: 630px)`);
  const [query, setQuery] = useState(mediaQuery.matches);

  useEffect(() => {
    window.onresize = () => {
      setQuery(mediaQuery.matches)

    };

  }, [mediaQuery])

  return (
    <div className="App">
      <div className="phone-rotation-indication-div"
        style={{
          display: !query && "none"
        }}
      >
        <PhoneRotationComp />
      </div>

      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/how-it-works" element={<HowItWorks />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
