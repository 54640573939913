import React, { useEffect, useState } from "react";
import "./compliance.scss";

const ItWave = "https://rentblob.blob.core.windows.net/techno-task/technotask-brochures-asset/it-wave.png";
const LogoText = "https://rentblob.blob.core.windows.net/techno-task/technotask-brochures-asset/it-logo-text.png";
const ITPhone = "https://rentblob.blob.core.windows.net/techno-task/technotask-brochures-asset/it-phone.png";

const Compliance = () => {
  const [phoneMove, setPhoneMove] = useState(false);

  useEffect(() => {
    setPhoneMove(true)
    const intervalId = setInterval(() => {
      setPhoneMove((prevValue) => !prevValue);
    }, 21000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="compliance-section-main-container">
      <div className="compliance-section"

      >
        <div className="compliance-left">
          <img src={ItWave} alt="" className="it-wave" />
          <div className="c-left-text">
            <img src={LogoText} alt="" className="it-logo-text" />
            <p>
              We have a <b>good compliance culture,</b> compliance is a focus{" "}
              <br /> of management and is integral to company operations.{" "}
            </p>
            <br />
            <p>
              An organization with a strong compliance culture has a <br />{" "}
              general awareness of compliance best practices and the <br /> risks
              associated with non-compliance and we are <br /> well versed with
              it.
            </p>
          </div>
        </div>
        <div
          className="compliance-right"
          style={{
            top: phoneMove && "-260%",
            right: phoneMove && "-25%",
          }}
        >
          <img src={ITPhone} alt="" className="it-phone" />
          <img src={ITPhone} alt="" className="it-phone-two" />
        </div>
      </div>
    </div>
  );
};

export default Compliance;
