import React from 'react';

//styles
import './key-features-comp.scss';

//images
const KeyImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/key.png';
const KeyFeaturesImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/key-features-img.png';
const contentsImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame965.png';


export default function KeyFeaturesComp({ Progress, PageProgress }) {
    const Progress0 = Progress[13]

    const progress1 = Progress[25]
    const progress2 = Progress[26]

    // console.log(window)

    return (
        <div className="key-features-comp-main-container"
            style={{
                opacity: PageProgress > progress1 && '1',
                transform: PageProgress > progress2 && 'translateY(-100vh)',
                zIndex: PageProgress < Progress0 && '-1'
            }}
        >

            <div className="key-features-main">
                <div className="top-key-holder">
                    <img src={KeyImg} alt="" className="key-img"
                        style={{
                            transform: PageProgress > progress1 && 'scale(-1,1)'
                        }}
                    />
                </div>
                <div className="bottom-key-holder">
                    <img src={KeyImg} alt="" className="key-img"
                        style={{
                            transform: PageProgress > progress1 && 'scale(-1,1)'
                        }}
                    />
                </div>

                <img src={KeyFeaturesImg} alt="" className="key-features-img"
                    style={{
                        opacity: PageProgress > progress1 && '1'
                    }}
                />

                <img src={contentsImg} alt="" className="contents-img"
                    style={{
                        transform: PageProgress > progress1 && 'translateY(0)'
                    }}
                />
            </div>
        </div>
    )
}
