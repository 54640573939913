import React, { useState, useMemo } from 'react'

//style
import './quality-assurance-comp.scss';

//imgs
const headingImd = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1138.png';
const frameWorkImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1139.png'
const insightsImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1140.png'
const managementImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1141.png'
const newHireTrainingsImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1142.png'
const emailReadingImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1143.png'
const auditsImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1144.png'
const bgLog = 'https://rentblob.blob.core.windows.net/techno-task/technotask-brochures-asset/Frame1107.png';


export default function QualityAssuranceComp({ Progress, PageProgress }) {
    const Progress1 = Progress[9];
    const Progress2 = Progress[10];

    const [textOpacity, setTextOpacity] = useState(false);
    useMemo(() => {
        !textOpacity && PageProgress > Progress1 && setTimeout(() => {
            setTextOpacity(true)
        }, 2500);

        PageProgress < Progress1 && setTextOpacity(false);

        // eslint-disable-next-line
    }, [PageProgress])

    return (
        <div className="quality-assurance-comp-main-container"
            style={{
                transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'translateY(0)' :
                    PageProgress > Progress2 && 'translateY(-100vh)',
                opacity: PageProgress > Progress1 && PageProgress < Progress2 && '1'
            }}
        >
            <div className="quality-assurance-comp-main">
                <img src={bgLog} alt="" className="bg-img" />

                <div className="circle-comp">
                    <img src={headingImd} alt="" className="logo-heading-img"
                        style={{
                            transform: PageProgress > Progress1 && 'translate(0) scale(1)'
                        }}
                    />
                    <img src={frameWorkImg} alt="" className="frame-one-img"
                        style={{
                            opacity: textOpacity && '1'
                        }}
                    />
                    <img src={insightsImg} alt="" className="frame-two-img"
                        style={{
                            opacity: textOpacity && '1'
                        }}
                    />
                    <img src={managementImg} alt="" className="frame-three-img"
                        style={{
                            opacity: textOpacity && '1'
                        }}
                    />
                    <img src={newHireTrainingsImg} alt="" className="frame-four-img"
                        style={{
                            opacity: textOpacity && '1'
                        }}
                    />
                    <img src={emailReadingImg} alt="" className="frame-five-img"
                        style={{
                            opacity: textOpacity && '1'
                        }}
                    />
                    <img src={auditsImg} alt="" className="frame-six-img"
                        style={{
                            opacity: textOpacity && '1'
                        }}
                    />

                </div>

            </div>

        </div>
    )
}
