import React, { useEffect, useState } from "react";
import "./style.scss";

// ----------- Images ------------------
const CRMLogo = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-logo.png";
const CRMLines = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-lines.png";
const CRMSubHeading = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-subheading.png";
const CRMLaptop = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-laptop-img3.png";
const CRMMail = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-mail.png";
const CRMFb = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-fb.png";
const CRMTwitter = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-twitter.png";
const CRMWhatsapp = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-whatsapp.png";
const CRMPhone = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-phone.png";
const CRMMessage = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/crm-message.png";

const CRMComp = () => {
  const [email, setEmail] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [phone, setPhone] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    setEmail(true);

    setTimeout(() => {
      setEmail(false);
    }, 1500);
  }, []);

  return (
    <div className="crm-main-div">
    <div className="crm-div">
      <div className="crm-lines-img">
        <img src={CRMLines} alt="" className="crm-lines" />
      </div>
      <div className="crm-laptop-img">
        <img src={CRMLaptop} alt="" className="crm-laptop" />
      </div>
      <div className="crm-content-wrapper">
        <img src={CRMLogo} alt="" className="crm-logo" />
        <h2 className="crm-heading">CRM</h2>
        <img src={CRMSubHeading} alt="" className="crm-subheading" />
        <p className="crm-text">
          Help agents in maintaining perfect balance between the power to handle
          various platforms and simplicity in managing responses within one
          frame.
        </p>
      </div>
      <div className="crm-social-icons">
        <div
          className="crm-mail"
          onMouseEnter={() => setEmail(true)}
          onMouseLeave={() => setEmail(false)}
          style={{
            // zIndex: email ? "2" : "1",
          }}
        >
          <img
            src={CRMMail}
            alt=""
            className="e-mail"
            style={{
              transform: email && "translateY(-4vw)",
              width: email && "12%",
            }}
          />
          <span
            style={{
              transform: email && "translate(0vw, -4vw) scale(1)",
              opacity: email && "1",
            }}
          >
            EMail
          </span>
          <p
            style={{
              transform: email && "translate(0vw, -4vw) scale(1)",
              opacity: email && "1",
            }}
          >
            Track and manage emails from one or various <br /> mailboxes by
            converting them into tickets.
          </p>
        </div>
        {/* ------------------------ Facebook Icon ------------------------------- */}
        <div
          className="crm-fb"
          onMouseEnter={() => setFacebook(true)}
          onMouseLeave={() => setFacebook(false)}
          style={{
            // zIndex: facebook ? "2" : "1",
          }}
        >
          <img
            src={CRMFb}
            alt=""
            className="crm-fb-img"
            style={{
              transform: facebook && "translateY(-4vw)",
              width: facebook && "12%",
            }}
          />
          <span
            style={{
              transform: facebook && "translate(0vw, -4vw) scale(1)",
              opacity: facebook && "1",
            }}
          >
            Facebook
          </span>
          <p
            style={{
              transform: facebook && "translate(0vw, -4vw) scale(1)",
              opacity: facebook && "1",
            }}
          >
            Link your support portal with Facebook and convert
            <br /> all the posts and direct messages into tickets.
          </p>
        </div>

        {/* ------------------------ Twitter Icon ------------------------------- */}
        <div
          className="crm-twitter"
          onMouseEnter={() => setTwitter(true)}
          onMouseLeave={() => setTwitter(false)}
          style={{
            // zIndex: twitter ? "2" : "1",
          }}
        >
          <img
            src={CRMTwitter}
            alt=""
            className="crm-twitter-img"
            style={{
              transform: twitter && "translateY(-4vw)",
              width: twitter && "12%",
            }}
          />
          <span
            style={{
              transform: twitter && "translate(0vw, -4vw) scale(1)",
              opacity: twitter && "1",
            }}
          >
            Twitter
          </span>
          <p
            style={{
              transform: twitter && "translate(0vw, -4vw) scale(1)",
              opacity: twitter && "1",
            }}
          >
            Respond and manage tweets by converting them
            <br /> into tickets, and handling them from helpdesk only.
          </p>
        </div>

        {/* ------------------------ Whatsapp Icon ------------------------------- */}
        <div
          className="crm-whatsapp"
          onMouseEnter={() => setWhatsapp(true)}
          onMouseLeave={() => setWhatsapp(false)}
          style={{
            // zIndex: whatsapp ? "2" : "1",
          }}
        >
          <img
            src={CRMWhatsapp}
            alt=""
            className="crm-whatsapp-img"
            style={{
              transform: whatsapp && "translateY(-4vw)",
              width: whatsapp && "12%",
            }}
          />
          <span
            style={{
              transform: whatsapp && "translate(-12.5vw, -4vw) scale(1)",
              opacity: whatsapp && "1",
            }}
          >
            Whatsapp
          </span>
          <p
            className="crm-whatsapp-para"
            style={{
              transform: whatsapp && "translate(-46vw, -4vw) scale(1)",
              opacity: whatsapp && "1",
            }}
          >
            Service your customers on WhatsApp and deliver
            <br /> a superfast digital customer experience
          </p>
        </div>

        {/* ------------------------ Phone Icon ------------------------------- */}
        <div
          className="crm-phone"
          onMouseEnter={() => setPhone(true)}
          onMouseLeave={() => setPhone(false)}
          style={{
            // zIndex: phone ? "2" : "1",
          }}
        >
          <img
            src={CRMPhone}
            alt=""
            className="crm-phone-img"
            style={{
              transform: phone && "translateY(-4vw)",
              width: phone && "12%",
            }}
          />
          <span
            style={{
              transform: phone && "translate(-11vw, -4vw) scale(1)",
              opacity: phone && "1",
            }}
          >
            Phone
          </span>
          <p
            style={{
              transform: phone && "translate(-43vw, -4vw) scale(1)",
              opacity: phone && "1",
            }}
          >
            Inbuilt integrated telephony set up that helps connecting
            <br /> with the customer as and when required.
          </p>
        </div>
        {/* ------------------------ Phone Icon ------------------------------- */}
        <div
          className="crm-message"
          onMouseEnter={() => setMessage(true)}
          onMouseLeave={() => setMessage(false)}
          style={{
            // zIndex: message ? "2" : "1",
          }}
        >
          <img
            src={CRMMessage}
            alt=""
            className="crm-message-img"
            style={{
              transform: message && "translateY(-4vw)",
              width: message && "12%",
            }}
          />
          <span
            style={{
              transform: message && "translate(-11.8vw, -4vw) scale(1)",
              opacity: message && "1",
            }}
          >
            Live Chat
          </span>
          <p
            style={{
              transform: message && "translate(-45.5vw, -4vw) scale(1)",
              opacity: message && "1",
            }}
          >
            Assist your customers through live interaction from
            <br /> your support portal and provide help on real time.
          </p>
        </div>
        <div className="crm-bottom-text"></div>
      </div>
    </div>
    </div>
  );
};

export default CRMComp;
