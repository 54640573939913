import React, { useState, useEffect } from 'react';

//styles
import './how-it-works.scss';

//libraries
import Stickyroll from "@stickyroll/react/stickyroll";


//components
import HowItWorksTopComp from './How-It-Works-Top-Comp/how-it-works-top-comp';
import SolutionComp from './Solution-Comp/solution-comp';
import ManagementToolsComp from './Management-Tools-Comp/management-tools-comp';
import OurTrainingProcessComp from './Our-Training-Process-Comp/our-training-process-comp';
import QualityAssuranceComp from './Quality-Assurance-Comp/quality-assurance-comp';
import RiskManagementComp from '../Home-Page/Risk-Management-Comp/risk-management-comp';
import OtherFeaturesComp from './Other-Features-Comp/other-features-comp';

export default function HowItWorks() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [pageProgress, setPageProgress] = useState(0);

    const progress = [
        0.066,
        0.13,
        0.2,
        0.26,
        0.33,
        0.4,
        0.46,
        0.53,
        0.6,
        0.66,
        0.73,
        0.8,
        0.85,
        0.92,
        0.98,//15

    ]


    return (
        <div className="how-it-works-main-container">
            {/* <p style={{ position: 'fixed', top: '0', color: 'red', zIndex: '10' }}>{pageProgress}</p> */}

            <Stickyroll
                pages={1}
                factor={50}
                onProgress={p => setPageProgress(p)}
            >
                <div className="home-page-main-section">
                    <HowItWorksTopComp Progress={progress} PageProgress={pageProgress} />
                    <SolutionComp Progress={progress} PageProgress={pageProgress} />
                    <ManagementToolsComp Progress={progress} PageProgress={pageProgress} />
                    <OurTrainingProcessComp Progress={progress} PageProgress={pageProgress} />
                    <QualityAssuranceComp Progress={progress} PageProgress={pageProgress} />
                    <RiskManagementComp Progress={progress} PageProgress={pageProgress} />
                    <OtherFeaturesComp Progress={progress} PageProgress={pageProgress} />



                </div>
            </Stickyroll>
        </div>
    )
}
