import React from 'react';

//styles
import './our-cofounders-comp.scss';


//imgs
const CoFunder1 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Group9.png';
const CoFunder2 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Group12.png';
const CoFunder3 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Group13.png';
const Logo = 'https://rentblob.blob.core.windows.net/sample-sales/home/logo-frame.png';



export default function OurCofoundersComp({ PageProgress, Progress }) {

    const progress1 = Progress[0];
    const progress2 = Progress[1];
    const progress3 = Progress[2];

    return (
        <div className="our-cofunders-comp-main-container"
            style={{
                transform: PageProgress > progress1 && 'translateY(0vh)',
                // PageProgress > progress3 && 'translateY(-100vh)',
                opacity: PageProgress > progress3 && '0'

            }}
        >
            <div className="main-container">
                <div className="outer-circle-one"
                    style={{
                        transform: PageProgress > progress2 && 'scale(2.8) translateY(10vh)'
                    }}
                />
                <div className="outer-circle-two"
                    style={{
                        transform: PageProgress > progress2 && 'scale(2.8) translateY(10vh)'
                    }}
                />

                <div className="outer-circle-three"
                    style={{
                        transform: PageProgress > progress2 && 'scale(2.8) translateY(10vh)'
                    }}
                />
                <div className="outer-circle-four"
                    style={{
                        transform: PageProgress > progress2 && 'translateY(52vh) scale(2.6)'
                    }}
                />
                <div className="outer-circle-five"
                    style={{
                        transform: PageProgress > progress2 && 'translateY(68vh) scale(2.5)'
                    }}
                />

                {/*------------------------------------------------------------------------------- */}

                <img src={CoFunder2} alt="" className="cofounder-img1"
                    style={{
                        transform: PageProgress < progress2 ? 'translate(10vw,48vh) rotate(-20deg) scaleX(-1)' :
                            PageProgress > progress3 && 'scale(0.4) translate(15vw, -150vh)'
                    }}
                />
                <img src={CoFunder3} alt="" className="cofounder-img3"
                    style={{
                        transform: PageProgress < progress2 ? 'translate(-17vw,45vh) rotate(20deg) scaleX(-1)' :
                            PageProgress > progress3 && 'scale(0.4) translate(-15vw, -150vh)'
                    }}
                />
                <img src={CoFunder1} alt="" className="cofounder-img2"
                    style={{
                        transform: PageProgress < progress2 ? 'translate(0,40vh) rotate(0deg)' :
                            PageProgress > progress3 && 'scale(0.4) translateY(-150vh)'
                    }}
                />


                {/* -------------------------------------------------------------- */}
                <div className="cofunder-headig-div"
                    style={{
                        top: PageProgress > progress2 && '15vh',
                        transform: PageProgress > progress3 && 'scale(0.4) translateY(-100vh)',
                    }}
                >
                    <img src={Logo} alt="" className="logo-img" />

                    <div className="heading">
                        OUR &nbsp;
                        <span
                            style={{
                                color: PageProgress > progress2 && '#fff'
                            }}
                        >
                            CO FOUNDERS
                        </span>
                    </div>

                </div>



            </div>

        </div>
    )
}
