import React from "react";
import "./ecosystem.scss";

const ESLogo = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/eco-system-logo.png";
const ESText = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/es-logo-text.png";
const ESImgOne = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/ec-img1.png";
const ESImgTwo = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/ec-img2.png";

const EcoSystem = () => {
  return (
    <div className="ecosystem-main">
      <div className="ecosystem-content">
        <div className="es-left">
          <img src={ESLogo} alt="" className="es-logo" />
          <img src={ESText} alt="" className="es-text" />
        </div>

        <div className="es-right-bg"></div>
        <img src={ESImgOne} alt="" className="es-img1" />
        <img src={ESImgTwo} alt="" className="es-img2" />
      </div>
    </div>
  );
};

export default EcoSystem;
