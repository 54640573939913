import React from 'react';

// style
import './why-us-comp.scss';

// images
const languageBlueIcon = 'https://rentblob.blob.core.windows.net/sample-sales/home/language-blue-icon.png';
const tier2cityIcon = 'https://rentblob.blob.core.windows.net/sample-sales/home/tier2city-icon.png';
const locationBlackIcon = 'https://rentblob.blob.core.windows.net/sample-sales/home/location-black-icon.png';
const suitcaseBlackIcon = 'https://rentblob.blob.core.windows.net/sample-sales/home/suitcase-black-icon.png';
const technologyBlueIcon = 'https://rentblob.blob.core.windows.net/sample-sales/home/technology-blue-icon.png';


const Logo = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';
const experienceFrame = 'https://rentblob.blob.core.windows.net/sample-sales/home/experience-frame.png';
const technologyFrame = 'https://rentblob.blob.core.windows.net/sample-sales/home/technology-frame.png';
const languageFrame = 'https://rentblob.blob.core.windows.net/sample-sales/home/language-frame.png';
const locationFrame = 'https://rentblob.blob.core.windows.net/sample-sales/home/location-frame.png';


export default function WhyUsComp({ Progress, PageProgress }) {
    const progress1 = Progress[2]
    const progress2 = Progress[3]

    return (
        <div className="why-us-comp-main-conainer"
            style={{
                transform: PageProgress > progress1 && PageProgress < progress2 ? 'translateY(0) scale(1)' :
                    PageProgress > progress2 && 'translateY(-100vh)',
                opacity: PageProgress > progress2 && '0'

            }}
        >
            <span className="circle-one"
                style={{
                    transform: PageProgress > progress1 && 'translateY(0)'
                }}
            >
                <span className="inner-circle" />
            </span>
            <span className="circle-two" />

            <div className="technology-div" >
                <img src={technologyFrame} alt="" className="heading-img" />
                <p>Agnostic to technology
                    Omni Channel availability
                    100% uptime from past months
                    All standard info sec protocols in place.</p>
            </div>
            <div className="language-div">
                <img src={languageFrame} alt="" className="heading-img" />
                <p>English (USA & UK <br />
                    Spanish LATAM <br />
                    German & French<br />
                    11 Indian Regional Languages.</p>

            </div>
            <div className="circuler-menu-one-contener">
                <div className="inner-circle">
                    <img src={Logo} alt="" className="logo-img" />
                    <p>WHY US</p>
                </div>

                <img src={technologyBlueIcon} alt="" className="technology-img"
                    style={{
                        transform: PageProgress < progress1 && 'translate(10vw, 10vh)'
                    }}
                />
                <img src={languageBlueIcon} alt="" className="language-img"
                    style={{
                        transform: PageProgress < progress1 && 'translate(-10vw, 10vh)'
                    }}
                />
                <img src={suitcaseBlackIcon} alt="" className="experience-mg"
                    style={{
                        transform: PageProgress < progress1 && 'translate(10vw)'
                    }}
                />
                <img src={locationBlackIcon} alt="" className="location-img"
                    style={{
                        transform: PageProgress < progress1 && 'translate(-10vw)'
                    }}
                />
                <img src={tier2cityIcon} alt="" className="tier2city-img"
                    style={{
                        transform: PageProgress < progress1 && 'translate(0,-15vh)'
                    }}
                />
                <p className="tier2city-text">
                    Bhopal has unlimited availability of trained resources. <br />
                    High cost efficiencies. <br />
                    Ready availability of infrastructure. <br />
                    Presence of major international BPOs within a 200km radius. <br />
                </p>
            </div>


            <div className="bottom-div"
            style={{
                opacity: PageProgress > progress1 && '1'
            }}
            >
                <div className="experience-div">
                    <img src={experienceFrame} alt="" className="heading-img" />
                    <p>100 years of collective leadership experience having built, nurtured and grown multi-million dollar contors.</p>

                </div>
                <div className="location-div" >
                    <img src={locationFrame} alt="" className="heading-img" />
                    <p>Present in 8 cities in INDIA with a proposed site at Pune. <br />
                        International centers Dubai & Cairo. <br />
                        Virtual center in LATAM. <br />
                        Sales office in Texas Director of Al.

                    </p>

                </div>

            </div>



        </div>
    )
}
