import React, { useState } from 'react';

//styles
import './country-slide.scss';

//imgs
const Logo = 'https://rentblob.blob.core.windows.net/sample-sales/home/logo-frame.png';
const CountrySlideImgOne = 'https://rentblob.blob.core.windows.net/sample-sales/home/Group38194.png';
const CountrySlideImgTwo = 'https://rentblob.blob.core.windows.net/sample-sales/home/Group38195.png';



export default function CountrySlide({ PageProgress, Progress }) {

    const Progress1 = Progress[9]
    const Progress2 = Progress[10]


    const [slideNum, setSlideNum] = useState(50)

    function handleLeftSlide() {
        slideNum === 50 && setSlideNum(-50)
    }
    function handleRightSlide() {
        slideNum === -50 && setSlideNum(50)
    }

    return (
        <div className="country-slide-main-container"
            style={{
                opacity: PageProgress > Progress1 && PageProgress < Progress2 && '1',
                zIndex: PageProgress > Progress1 && PageProgress < Progress2 && 1

            }}
        >
            <div className="main-container">

                <img src={Logo} alt="" className="logo-img"
                    style={{
                        transform: PageProgress > Progress2 && 'translate(50vw)',
                        opacity: PageProgress > Progress2 && '0'

                    }}
                />
                <p className='our-presence-heading'
                    style={{
                        transform: PageProgress > Progress2 && 'translate(50vw)',
                        opacity: PageProgress > Progress2 && '0'
                    }}
                >
                    OUR <span className="span1">PRESENCE</span><br /><span className="span2">DELIVERY CENTERS</span>
                </p>


                <div className="slider"
                    style={{
                        transform: PageProgress > Progress2 && 'translate(100vw)',
                        opacity: PageProgress > Progress2 && '0'

                    }}
                >

                    <div className="slides">
                        <div className="slide-img"
                            style={{
                                transform: `translate(${slideNum}%)`
                            }}
                        >
                            <div className="img1">
                                <img src={CountrySlideImgOne} alt="" className="country-slide-img" />
                            </div>
                            <div className="img2">
                                <img src={CountrySlideImgTwo} alt="" className="country-slide-img" />
                            </div>

                        </div>


                    </div>

                    <div
                        className="left-btn"
                        onClick={handleRightSlide}
                        style={{
                            display: slideNum === 50 && 'none'
                        }}
                    >
                        <span className="icon-span">&#x2039;</span>
                    </div>

                    <div
                        className="right-btn"
                        onClick={handleLeftSlide}
                        style={{
                            display: slideNum === -50 && 'none'
                        }}
                    >
                        <span className="icon-span">&#x203A;</span>
                    </div>

                </div>



            </div>

        </div>
    )
}
