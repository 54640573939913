import React from "react";
import "./style.scss";
const AMask = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/analytics-mask.png";
const APhone = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/analytics-phone.png";

const AFirst = ({ val }) => {
  return (
    <div className="analytics-content-one">
      <div
        className="analytics-left"
        style={{
          transform: !val && "translateX(0vw)",
        }}
      >
        <img src={AMask} alt="" className="amask" />
        <div className="a-texts">
          <div className="a-left-suheading">
            Primary challenges faced by Contact Centers as they attempt to
            understand the voice of the customer.
          </div>
          <p className="a-line">
            1. Contact centers rely too much on manual processes, making it
            impossible to achieve complete visibility into agent-customer
            interaction.
          </p>
          <p className="a-line">
            2. Contact Center follows random, manual call-sampling methods,
            which capture less than 5 % of all interactions, producing
            incomplete (or unrepresentative) raw data sets
          </p>
          <p className="a-line">
            3. Contact centers are bogged down by legacy processes: Most contact
            centers use spreadsheets or word documents for evaluating and
            analysis interactions
          </p>
          <p className="a-line">
            4. Lack of insight into customer problems, complaints and
            preferences as customer interaction analysis are done in isolation.
          </p>
        </div>
      </div>
      <div
        className="analytics-right"
        style={{
          transform: !val && "translateX(0vw)",
        }}
      >
        <img src={APhone} alt="" />
      </div>
    </div>
  );
};

export default AFirst;
