import React, { useState, useEffect, useMemo } from 'react';

//styles
import './how-it-works-top-comp.scss';

//data
import { logoCircle } from './logoData';

// images
// const HowText = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/how-text.png';
const HowText = 'https://i.ibb.co/QKFRkSS/how-text.png';
const logoMask = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/colored-group-circles.png';

// const approachText = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/approach-text.png';
const approachText = 'https://i.ibb.co/ykmpgcJ/approach-text.png';
const approachBG = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/approach-bg.png';
const decidionMakingIcon = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/decision-making.png';

export default function HowItWorksTopComp({ Progress, PageProgress }) {
    const [isCircleApart, setIsCircleApart] = useState(true)
    const [logoBounce, setLogoBounce] = useState(false)
    const [maskHeading, setmaskHeading] = useState(false)
    const [iconMaskSlide, setIconMaskSlide] = useState(false)


    const Progress1 = Progress[0];
    const Progress2 = Progress[1];


    useEffect(() => {
        setTimeout(() => {
            setLogoBounce(true)
        }, 800);
    }, [])

    useMemo(() => {
        logoBounce && setTimeout(() => {
            setIsCircleApart(false)
        }, 3500);
    }, [logoBounce])

    useMemo(() => {
        !isCircleApart && setTimeout(() => {
            setmaskHeading(true)
        }, 100);
    }, [isCircleApart])

    useEffect(() => {
        !iconMaskSlide && setTimeout(() => {
            setIconMaskSlide(true)
        }, 6000);

        iconMaskSlide && setTimeout(() => {
            setIconMaskSlide(false)
        }, 6000);
    }, [iconMaskSlide])


    return (
        <div className="how-it-works-top-comp-main-container"
            style={{
                opacity: PageProgress > Progress2 && '0'
            }}
        >
            <div className="how-it-works-top-comp-main">

                <div className="colored-circle"
                    style={{
                        animation: logoBounce && 'logoBounceKF 3s ease-in-out',
                        transform: logoBounce && 'translateY(0vh)'
                    }}
                >
                    {
                        logoCircle.map((item, i) => {
                            return <div
                                key={i}
                                className='circle-div'
                                style={{
                                    marginLeft: i === 0 && isCircleApart ? '3.4vw' : i === 0 && !isCircleApart ? '-100vw' :
                                        i === 1 && isCircleApart ? '5.7vw' : i === 1 && !isCircleApart ? '-90vw' :
                                            i === 2 && isCircleApart ? '8.8vw' : i === 2 && !isCircleApart ? '-80vw' :
                                                i === 3 && isCircleApart ? '11.1vw' : i === 3 && !isCircleApart ? '-70vw' :
                                                    i === 4 && isCircleApart ? '14.2vw' : i === 4 && !isCircleApart ? '80vw' :
                                                        i === 5 && isCircleApart ? '16.5vw' : i === 5 && !isCircleApart ? '90vw' :
                                                            i === 6 && isCircleApart ? '19.7vw' : i === 6 && !isCircleApart ? '100vw' :
                                                                i === 7 && isCircleApart ? '21.8vw' : i === 7 && !isCircleApart && '110vw',

                                    width: i === 0 && !isCircleApart ? '6vw' :
                                        i === 7 && !isCircleApart ? '6vw' :
                                            i === 1 && !isCircleApart ? '5.5vw' :
                                                i === 6 && !isCircleApart ? '5.5vw' :
                                                    i === 2 && !isCircleApart ? '5vw' :
                                                        i === 5 && !isCircleApart && '5vw',

                                    height: i === 0 && !isCircleApart ? '6vw' :
                                        i === 7 && !isCircleApart ? '6vw' :
                                            i === 1 && !isCircleApart ? '5.5vw' :
                                                i === 6 && !isCircleApart ? '5.5vw' :
                                                    i === 2 && !isCircleApart ? '5vw' :
                                                        i === 5 && !isCircleApart && '5vw',
                                }}
                            >
                                <span className="before-span"
                                    style={{
                                        backgroundColor: item.color,
                                    }}
                                >
                                </span>

                            </div>
                        })
                    }
                </div>

                <div className="how-it-works-heading"
                    style={{
                        transform: maskHeading && PageProgress < Progress1 ? 'scale(1)' :
                            maskHeading && PageProgress > Progress1 && 'scale(60) translateY(01vh)',
                        opacity: PageProgress > Progress1 && '0'

                    }}
                >
                    <div className="heading-mask-div"
                        style={{
                            maskImage: `url(${HowText})`,
                            WebkitMaskImage: `url(${HowText})`
                        }}
                    >
                        <img src={logoMask} alt="" className="heading-mask-bg"
                            style={{
                                transform: maskHeading && 'scale(-1) rotate(0deg)'
                            }}
                        />
                    </div>
                    &nbsp; <span>IT</span> <br />
                    <strong>WORKS</strong>
                </div>

                <div className="transition-approach-heading"
                    style={{
                        transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'scale(1)' :
                            PageProgress > Progress2 && 'scale(100) translate(-0.8vw)',
                        opacity: PageProgress > Progress1 && PageProgress < Progress2 && '1'
                    }}
                >
                    <p className='transition-heading'>Transition</p>
                    <div className="icon-mask-div"
                        style={{
                            WebkitMaskImage: `url(${decidionMakingIcon})`,
                            maskImage: `url(${decidionMakingIcon})`
                        }}
                    >
                        <img src={logoMask} alt="" className="colored-circle-img"
                            style={{
                                transform: iconMaskSlide && 'translate(-70%)'
                            }}
                        />
                    </div>
                    <div className="approach-mask-div"
                        style={{
                            WebkitMaskImage: `url(${approachText})`,
                            maskImage: `url(${approachText})`
                        }}
                    >
                        <img src={approachBG} alt="" className="approach-mask-text"
                            style={{
                                transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'translate(-10vw, 20vh) scale(1.2)' :
                                    PageProgress > Progress2 && 'translate(10vw, -20vh) scale(1)'
                            }}
                        />
                    </div>

                    <p className="bottom-content-text">
                        <span className="span1">TTBS</span>  transition approach has proven and rigorous <br />
                        approach to onboarding new <span className='span2'>BPS</span> work.
                    </p>
                </div>





            </div>
        </div >
    )
}
