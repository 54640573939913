import React, { useState, useMemo } from 'react'

//style
import './risk-management-comp.scss';


// imgs
const logoImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';
const lampImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Group38370.png';
const groupedCircleImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1145.png';
const headingImg = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1147.png';
const mainContents = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1136.png';


export default function RiskManagementComp({ Progress, PageProgress }) {
    const Progress1 = Progress[10];
    const Progress2 = Progress[11];
    const Progress3 = Progress[12];
    const Progress4 = Progress[13];


    const [textOpacity, setTextOpacity] = useState(false);
    useMemo(() => {
        !textOpacity && PageProgress > Progress1 && setTimeout(() => {
            setTextOpacity(true)
        }, 2000);

        PageProgress < Progress1 && setTextOpacity(false);
        // eslint-disable-next-line
    }, [PageProgress])

    return (
        <div className="risk-management-comp-main-conatiner"
            style={{
                opacity: PageProgress > Progress1 && '1',
                transform: PageProgress > Progress4 && 'translateY(-100vh)'

            }}
        >
            <div className="risk-management-comp-main">
                <img src={groupedCircleImg} alt="" className="grouped-circle-img"
                    style={{
                        transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'translateY(0)' :
                            PageProgress > Progress2 && PageProgress < Progress4 ? 'translateY(0vh) scale(1.8)' :
                            PageProgress > Progress4 && 'translateY(-150vh) scale(1)',
                        opacity: PageProgress > Progress1 && '1'
                    }}
                />

                <div className="heading-div"
                    style={{
                        transform: PageProgress > Progress1 && 'translateY(0)',
                        opacity: PageProgress > Progress1 && '1',
                        zIndex: textOpacity && '2'
                    }}
                >
                    <img src={logoImg} alt="" className="logo-img"
                        style={{
                            opacity: PageProgress > Progress2 && '0'
                        }}
                    />
                    <img src={lampImg} alt="" className="lamp-img"
                        style={{
                            transform: PageProgress > Progress2 && 'translate(30vw, -10vh) scaleX(1.2)'
                        }}
                    />
                    <img src={lampImg} alt="" className="lamp-img"
                        style={{
                            transform: PageProgress > Progress2 && 'translate(-30vw, -10vh) scaleX(1.2)'
                        }}
                    />
                    <spna className="perceived-rick-text"
                        style={{
                            transform: PageProgress > Progress2 && 'translate(-30vw, -10vh)',
                            opacity: PageProgress > Progress2 && '1'
                        }}
                    >Perceived Rick</spna>
                    <spna className="mitigation-plan-text"
                        style={{
                            transform: PageProgress > Progress2 && 'translate(30vw, -10vh)',
                            opacity: PageProgress > Progress2 && '1'
                        }}
                    >Mitigation Plan</spna>


                </div>
                <img src={headingImg} alt="" className="risk-management-heading"
                    style={{
                        opacity: textOpacity && PageProgress < Progress2 && '1',
                        zIndex: textOpacity && '2'

                    }}
                />

                <div className="main-contents-div"
                    style={{
                        opacity: PageProgress < Progress2 && '0'
                    }}
                >
                    <img src={mainContents} alt="" className="main-contents-img"
                        style={{
                            transform: PageProgress > Progress3 && 'translateY(-41vw)'
                        }}
                    />

                </div>



            </div>
        </div>
    )
}
