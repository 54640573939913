import React from 'react';

import { Tilt } from 'react-tilt';
//style
import './management-tools-comp.scss';

import HRSuitComp from '../Hr-Suit-Comp/hr-suit-comp';


//images
const GroupedCircle = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/grouped-circle.png';
const frame1 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1069.png';
const frame2 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1071.png';
const frame3 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1070.png';
const frame4 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1072.png';
const frame5 = 'https://rentblob.blob.core.windows.net/sample-sales/how-it-works/Frame1073.png';
// const managementText = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/management-text.png';
const managementText = 'https://i.ibb.co/jrBcpsS/management-text.png';

const toolsText = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/tools-text.png';

const managementBG = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/how-it-works/management-bg.png';


export default function ManagementToolsComp({ Progress, PageProgress }) {
    const Progress1 = Progress[3];
    const Progress2 = Progress[4];
    const Progress3 = Progress[6];


    return (
        <div className="management-tools-comp-main-container"
            style={{
                transform: PageProgress > Progress1 && PageProgress < Progress3 ? 'translateY(0)' :
                    PageProgress > Progress3 && 'translateY(-100vh)',
                backgroundColor: PageProgress > Progress2 && PageProgress < Progress3 && '#000',
            }}
        >
            <HRSuitComp Progress={Progress} PageProgress={PageProgress} />
            <div className="management-tools-comp-main">
                <img src={GroupedCircle} alt="" className="bg-circle-group-one"
                    style={{
                        transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'translate(0)' :
                            PageProgress > Progress2 && PageProgress < Progress3 ? 'translate(-75vw, -5vh)' :
                                PageProgress > Progress3 && 'translate(-100vw, -5vh)',
                    }}
                />
                <img src={GroupedCircle} alt="" className="bg-circle-group-two"
                    style={{
                        transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'scale(-1) translate(0)' :
                            PageProgress > Progress2 && PageProgress < Progress3 ? 'translate(90vw, -5vh) scale(-1)' :
                                PageProgress > Progress3 && 'translate(150vw, -5vh)',

                    }}
                />


                <div className="top-section"
                    style={{
                        opacity: PageProgress > Progress2 && '0',
                        transform: PageProgress > Progress2 && 'translateY(-100vh) scale(0.6)'
                    }}
                >
                    <div className="management-heading-div">
                        <span className="span-text">Enabled by a mature set of transition</span>

                        <div className="management-text-mask-div"
                            style={{
                                WebkitMaskImage: `url(${managementText})`,
                                maskImage: `url(${managementText})`
                            }}
                        >
                            <img src={managementBG} alt="" className="management-bg-img"
                                style={{
                                    transform: PageProgress > Progress1 && 'translate(-45%)'

                                }}
                            />
                        </div>

                        <div className="tools-text-div">
                            <img src={toolsText} alt="" className="tools-text-img" />
                        </div>
                    </div>
                </div>
                <div className="bottom-section"
                    style={{
                        opacity: PageProgress > Progress2 && '0',
                        transform: PageProgress > Progress2 && 'translateY(-100vh) scale(0.6)'
                    }}
                >
                    <div className="top-part">
                        <Tilt className='tilt-comp' options={{ max: 25, scale: 1 }}>
                            <img src={frame1} alt="" className="frame-img" />

                        </Tilt>
                        <Tilt className='tilt-comp' options={{ max: 25, scale: 1 }}>

                            <img src={frame4} alt="" className="frame-img" />
                        </Tilt>
                        <Tilt className='tilt-comp' options={{ max: 25, scale: 1 }}>
                            <img src={frame5} alt="" className="frame-img" />

                        </Tilt>


                    </div>
                    <div className="bottom-part">
                        <Tilt className='tilt-comp' options={{ max: 25, scale: 1 }}>
                            <img src={frame3} alt="" className="frame-img" />
                        </Tilt>
                        <Tilt className='tilt-comp' options={{ max: 25, scale: 1 }}>
                            <img src={frame2} alt="" className="frame-img" />
                        </Tilt>
                    </div>
                </div>


            </div>
        </div>
    )
}
