import React from 'react';

//styles
import './upper-content.scss';

//components
import CountrySlide from './Country-Slides/country-slide';

//imgs
const Globe = 'https://rentblob.blob.core.windows.net/sample-sales/home/globe3.png';

export default function UpperContent({ PageProgress, Progress }) {

    const Progress1 = Progress[7]
    const Progress2 = Progress[8]
    const Progress3 = Progress[9]
    const Progress4 = Progress[10]


    return (
        <div className="upper-content-main-container" style={{
            // transform: PageProgress > Progress4 && 'translateY(-50vh) scale(0.6)'
        }}>
            <div className="main-section">


                <div className="globe-img-div"
                    style={{
                        right: PageProgress > Progress1 && PageProgress < Progress3 ? '-8%' :
                            PageProgress > Progress3 && '86%',
                        transform: PageProgress > Progress4 && 'translate(-50vw)',
                        opacity: PageProgress > Progress4 && '0'
                    }}
                >
                    <img src={Globe} alt=""
                        style={{
                            transform: PageProgress > Progress1 && PageProgress < Progress2 ? 'rotate(0deg)' :
                                PageProgress > Progress2 && 'rotate(-45deg)'
                        }}
                    />
                </div>

                <div className="heading"
                    style={{
                        opacity: PageProgress > Progress1 && '1',
                        transform: PageProgress > Progress3 && 'translate(-50vw)'
                    }}
                >
                    GLOBAL <span>PRESENCE</span>
                </div>

                <div className="left-slide-contents-one"
                    style={{
                        transform: PageProgress > Progress1 && PageProgress < Progress2 && 'translate(0vw)'
                    }}
                >
                    <span className='span2'>We are present in</span>
                    <span className="number-span">2</span>
                    out of&nbsp;
                    <span className="span1">5 BPO</span>
                    destinations in world
                </div>
                <div className="left-slide-contents-two"
                    style={{
                        transform: PageProgress > Progress2 && PageProgress < Progress3 && 'translate(0vw)'
                    }}
                >
                    <p> Workforce Strength <span className='span1'>5000+</span></p>
                    <p> Infrastructure <span className='span1'>347K <span className="inner-span">Sq. Ft.</span></span></p>



                </div>
                <CountrySlide PageProgress={PageProgress} Progress={Progress} />
            </div>

        </div>
    )
}
