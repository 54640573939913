import React from "react";
import "./style.scss";

const AArrow = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/arrow.png";

const ASecond = ({ val }) => {
  return (
    <div className="a-second-main">
      <div
        className="aleft-box"
        style={{
          transform: val && "translateX(0vw)",
        }}
      >
        <div className="abox-one">
          <p>
            Random sampling of customer interactions generates insights based on
            unreliable data
          </p>
        </div>
        <div className="abox-two">
          <p>No specific feedback for agent performance improvement</p>
        </div>
        <div className="abox-three">
          <p>
            Manual evaluation & reporting with multiple tools is inefficient,
            error prone and time consuming
          </p>
        </div>
      </div>

      <div
        className="acenter-box"
        style={{
          transform: val && "translateX(0vw)",
        }}
      >
        <div className="c-box-one">
          <div className="c-box">
            <p>
              <span>100%</span> Customer <br /> Interaction Analysis
            </p>
            <img src={AArrow} alt="" />
          </div>
        </div>
        <div className="c-box-two">
          <div className="c-box">
            <p>
              <span>100%</span> Customer <br /> Interaction Analysis
            </p>
            <img src={AArrow} alt="" />
          </div>
        </div>
        <div className="c-box-three">
          <div className="c-box">
            <p>
              <span>QA </span><br /> Digitization
            </p>
            <img src={AArrow} alt="" />
          </div>
        </div>
      </div>

      <div
        className="aright-box"
        style={{
          transform: val && "translateX(0vw)",
          opacity: val && "1",
        }}
      >
        <div className="abox-one">
          <p>
            Smart Sampling -{" "}
            <span>
              Choose only the calls with problems in soft skills, escalations,
              dead air, ZTP, AHT.
            </span>
          </p>
        </div>
        <div className="abox-two">
          <p>
            Streamlined agent coaching with <span>personalized feedback</span>
          </p>
        </div>
        <div className="abox-three">
          <p>
            Faster evaluation process with{" "}
            <span>auto filled QA forms, transcripts & reports</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ASecond;
