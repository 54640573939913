import React from 'react';

//styles
import './customer-support.scss';

//components
import DynamicCircle from '../../../components/Dynamic-Circle-Comp/dynamic-circle';

// images
const vector = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1033.png';

const logoImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';
const eComContentOne = 'https://rentblob.blob.core.windows.net/techno-task/technotask-sales-deck/home/Frame1034.png';
const eComContentTwo = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1035.png';
const evCapabilitiesOne = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1037.png';
const evCapabilitiesTwo = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1035.png';
const fintechHeading = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1039.png';
const fintechCapabilitiesOne = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1038.png';
const fintechCapabilitiesTwo = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1040.png';
const technotaskInsuranceCapabilities1 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1041.png';
const technotaskInsuranceCapabilities2 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1042.png';
const technotaskInsuranceCapabilities3 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1043.png';
const technotaskInsuranceCapabilities4 = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1044.png';
const lampImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/lamp-img.png';
const healthCareCapabilitiesOne = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1046.png';
const healthCareCapabilitiesTwo = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1047.png';



export default function CustomerSupport({ PageProgress, Progress }) {

    const Progress1 = Progress[12]
    const Progress2 = Progress[13]
    const Progress3 = Progress[14]
    const Progress4 = Progress[15]
    const Progress5 = Progress[16]
    const Progress6 = Progress[17]
    const Progress7 = Progress[18]
    const Progress8 = Progress[19]
    const Progress9 = Progress[20]
    const Progress10 = Progress[21]
    const Progress11 = Progress[22]
    const Progress12 = Progress[23]
    const Progress13 = Progress[24]
    const Progress14 = Progress[25]

    return (
        <div className="customer-support-main-container"
            style={{
                opacity: PageProgress >= Progress1 && '1'
            }}
        >
            <div className="customer-support-main"
                style={{
                    transform: PageProgress > Progress1 && 'translateY(0)',
                    background: PageProgress > Progress1 && '#fff'

                }}
            >
                <div className="bg-color-div"
                    style={{
                        opacity: PageProgress > Progress1 && PageProgress < Progress2 ? '1' :
                            PageProgress > Progress8 && PageProgress < Progress14 && '1',

                    }}
                />

                <div className="bg-circle"
                    style={{
                        transform: PageProgress >= Progress1 && 'translateY(0)',
                        opacity: PageProgress < Progress1 ? '0' :
                            PageProgress > Progress2 && '0',


                    }}
                >
                  
                </div>
                <DynamicCircle PageProgress={PageProgress} Progress={Progress} />

                <img src={vector} alt="" className="vector-img"
                    style={{
                        transform: PageProgress > Progress2 && 'translateY(0vh)',
                        opacity: PageProgress > Progress6 && '0'

                    }}
                />

                <div className="left-contents">

                    <img src={logoImg} alt="" className="logo-img"
                        style={{
                            transform: PageProgress < Progress2 ? 'translateY(-20vh)' :
                                PageProgress > Progress3 && PageProgress < Progress12 ? 'scale(1.2) translate(0.4vw)' :
                                    PageProgress > Progress12 && PageProgress < Progress13 ? 'scale(1.1) translate(15.6vw)' :
                                        PageProgress > Progress13 && PageProgress < Progress14 ? 'scale(1.1) translate(-2vw)' :
                                            PageProgress > Progress14 && 'scale(1.1) translate(-2vw, -40vh)',

                            opacity: PageProgress > Progress6 && PageProgress < Progress8 ? '0' :
                                PageProgress > Progress8 && '1'

                        }}
                    />

                    {/* -------------------------------------------------------------------------- */}


                    <img src={eComContentOne} alt="" className="ecom-content-one"
                        style={{
                            transform: PageProgress > Progress2 && PageProgress < Progress3 && 'translate(0vw)'
                        }}
                    />
                    <img src={eComContentTwo} alt="" className="ecom-content-two"
                        style={{
                            transform: PageProgress > Progress3 && PageProgress < Progress4 && 'translate(0vw)'
                        }}
                    />

                    {/* -------------------------------------------------------------------------- */}

                    <img src={evCapabilitiesOne} alt="" className="ev-capabilities-one"
                        style={{
                            transform: PageProgress > Progress4 && PageProgress < Progress5 && 'translate(0vw)'
                        }}
                    />
                    <img src={evCapabilitiesTwo} alt="" className="ev-capabilities-two"
                        style={{
                            transform: PageProgress > Progress5 && PageProgress < Progress6 && 'translate(0vw)'
                        }}
                    />

                    {/* -------------------------------------------------------------------------- */}


                    <div className="rectangle-one"
                        style={{
                            opacity: PageProgress > Progress6 && '1',
                            transform: PageProgress > Progress8 && 'translateY(-25vh)'
                        }}
                    />
                    <div className="rectangle-two"
                        style={{
                            opacity: PageProgress > Progress6 && '1',
                            transform: PageProgress > Progress8 && 'translateY(25vh)'

                        }}
                    />

                    <img src={fintechHeading} alt="" className="fintech-heading-img"
                        style={{
                            transform: PageProgress > Progress6 && PageProgress < Progress7 && 'translate(0vw)'
                        }}
                    />
                    <img src={fintechCapabilitiesOne} alt="" className="fintech-capabilities-one"
                        style={{
                            transform: PageProgress > Progress6 && PageProgress < Progress7 && 'translate(0vw)'
                        }}
                    />
                    <img src={fintechCapabilitiesTwo} alt="" className="fintech-capabilities-two"
                        style={{
                            transform: PageProgress > Progress7 && PageProgress < Progress8 && 'translate(0vw)'
                        }}
                    />

                    {/* -------------------------------------------------------------------------- */}


                    <img src={technotaskInsuranceCapabilities1} alt="" className="pre-sales-and-support"
                        style={{
                            transform: PageProgress > Progress8 && PageProgress < Progress9 ? 'translateY(0) scale(1)' :
                                PageProgress > Progress9 && 'translateY(-30vh) scale(0.5)',
                            opacity: PageProgress > Progress9 && '0',
                        }}
                    />

                    <img src={technotaskInsuranceCapabilities2} alt="" className="new-business-support"
                        style={{
                            transform: PageProgress > Progress9 && PageProgress < Progress10 ? 'translateY(0) scale(1)' :
                                PageProgress > Progress10 && 'translateY(-30vh) scale(0.5)',
                            // opacity: PageProgress > Progress10 && '0',
                            filter: PageProgress > Progress9 && PageProgress < Progress10 && 'none',
                            opacity: PageProgress > Progress8 && PageProgress < Progress10 && '1'

                        }}
                    />

                    <img src={technotaskInsuranceCapabilities3} alt="" className="policy-services-and-customer-care"
                        style={{
                            transform: PageProgress > Progress10 && PageProgress < Progress11 ? 'translateY(0) scale(1)' :
                                PageProgress > Progress11 && 'translateY(-30vh) scale(0.5)',
                            // opacity: PageProgress > Progress11 && '0',
                            filter: PageProgress > Progress10 && PageProgress < Progress11 && 'none',
                            opacity: PageProgress > Progress9 && PageProgress < Progress11 && '1'

                        }}
                    />

                    <img src={technotaskInsuranceCapabilities4} alt="" className="claims-support"
                        style={{
                            transform: PageProgress > Progress11 && PageProgress < Progress12 ? 'translateY(0) scale(1)' :
                                PageProgress > Progress12 && 'translate(-50vh) scale(1)',
                            // opacity: PageProgress > Progress12 && '0',
                            filter: PageProgress > Progress11 && PageProgress < Progress12 && 'none',
                            opacity: PageProgress > Progress10 && PageProgress < Progress12 && '1'

                        }}
                    />

                    {/* -------------------------------------------------------------------------- */}


                    <img src={lampImg} alt="" className="lamp-img"
                        style={{
                            opacity: PageProgress > Progress12 && '1',
                            transform: PageProgress > Progress13 && PageProgress < Progress14 ? 'translate(-14vw, 4vh) scaleX(1.8)' :
                                PageProgress > Progress14 && 'translate(-80vw, 4vh) scaleX(1.8)'
                        }}
                    />
                    <img src={healthCareCapabilitiesOne} alt="" className="health-care-capabilities-one"
                        style={{
                            transform: PageProgress > Progress12 && PageProgress < Progress13 ? 'translate(0)' :
                                PageProgress > Progress13 && 'translate(-80vw)',
                        }}
                    />
                    <p className="health-care-capabilities-heading"
                        style={{
                            opacity: PageProgress > Progress13 && '1',
                            transform: PageProgress > Progress14 && 'translateY(-40vh)'

                        }}
                    >
                        <b>TOOLS</b>&nbsp; USED
                    </p>
                    <img src={healthCareCapabilitiesTwo} alt="" className="health-care-capabilities-two"
                        style={{
                            transform: PageProgress > Progress13 && PageProgress < Progress14 ? 'translate(0)' :
                                PageProgress > Progress14 && 'translate(-80vw)'
                        }}
                    />


                </div>



            </div>
        </div>
    )
}
