import React from 'react';

//styles
import './journey-comp.scss';

//images
const TextContent = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1011.png';
const BoxesGroup = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1009.png';
const BoxImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame1007.png';
const logoImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';

export default function JourneyComp({ Progress, PageProgress }) {

    const progress1 = Progress[5]
    const progress2 = Progress[6]


    return (
        <div className="journey-comp-main-container"
            style={{
                transform: PageProgress > progress2 && 'translate(-100vw)',
                opacity: PageProgress < progress1 && '0',

            }}
        >
            <div className="journey-comp-main"
                style={{
                    transform: PageProgress > progress1 && 'translate(0vw)'
                }}
            >
                <div className="left-section">
                    <img src={logoImg} alt="" className="logo-img" />
                    <div className="heading-div">
                        <p className='heading'>Journey</p>
                        <p className='heading-mirror'>Journey</p>
                    </div>

                    <img src={TextContent} alt="" className="text-content-img" />

                </div>
                <div className="right-section">

                    <img src={BoxImg} alt="" className="rectangle1-div" />
                    <img src={BoxImg} alt="" className="rectangle2-div" />
                    <img src={BoxesGroup} alt="" className="boxes-group-img" />

                </div>

            </div>
        </div>
    )
}
