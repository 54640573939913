import React, { useState } from "react";
import "./style.scss";

import AFirst from "./AFirst";
import ASecond from "./ASecond";

const Alogo = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/analytics-logo.png";
const ALineOne = "https://rentblob.blob.core.windows.net/sample-sales/how-it-works/a-line.png";

const Analytics = () => {
  const [val, setVal] = useState(false);
  return (
    <div className="analytics-main-div">
      <div className="analytics">
        <img
          src={ALineOne}
          alt=""
          className="aline"
          style={{
            transform: !val
              ? "translate(-50%,-50%) rotate(-15deg)"
              : val && "translate(-50%,-50%) rotate(0deg)",
          }}
        />

        <div className="analytics-upper">
          <img src={Alogo} alt="" />
          <h2>Speech Analytics</h2>
        </div>

        <div className="analytics-first-wrapper">
          <AFirst val={val} />{" "}
        </div>

        <div className="analytics-second-wrapper">
          <ASecond val={val} />
        </div>

        <div className="analytics-btn">
          <button onClick={() => setVal(!val)}>
            {
              val ? 'Back to Industry Challenges'
                :
                'More Details'
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
