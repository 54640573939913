import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//styles
import './our-offering.scss';


//libraries
import { Tilt } from 'react-tilt';

//images
const waterFrame = 'https://rentblob.blob.core.windows.net/sample-sales/home/water-frame.gif';
const sphereball = 'https://rentblob.blob.core.windows.net/sample-sales/home/sphere-ball.png';
const ourOfferingsHeading = 'https://rentblob.blob.core.windows.net/sample-sales/home/our-offerings-heading.png';
const logo = 'https://rentblob.blob.core.windows.net/sample-sales/home/technotask-logo-one.png';

const frame1 = 'https://rentblob.blob.core.windows.net/sample-sales/home/frame-three.png';
const frame2 = 'https://rentblob.blob.core.windows.net/sample-sales/home/frame-two.png';
const frame3 = 'https://rentblob.blob.core.windows.net/sample-sales/home/frame-one.png';


export default function KeyFeature({ Progress, PageProgress }) {

    const [onCardHover, setOnCardHover] = useState(false);

    const onMouseOver = () => {
        setOnCardHover(true)
    }
    const onMouseOut = () => {
        setOnCardHover(false)
    }

    const progress1 = Progress[0]

    return (
        <div className="our-offering-main-container"
            style={{
                transform: PageProgress > progress1 && 'translateY(-100vh)'
            }}
        >

            <div className="our-offering-main">

                <div className="left-water-frame"
                    onMouseOver={onMouseOut}
                    style={{
                        backgroundImage: `url(${waterFrame})`,
                        width: onCardHover && '25%'

                    }}
                >
                    <Tilt className='tilt-comp' options={{ max: 35, scale: 1, }}>

                        <img src={ourOfferingsHeading} alt="" className="our-offerings-heading-imgs" />
                    </Tilt>

                    <img src={logo} alt="" className="logo-img" />


                    <Link
                        to='/how-it-works'
                        className='link-comp'
                        onClick={() => {
                            window.scrollTo(0, 0)
                        }}
                    >Process Details</Link>

                </div>

                <div className="right-content-frame"
                    style={{
                        width: onCardHover && '75%'

                    }}
                >

                    {[...Array(8)].map((_, i) => {
                        return (<img
                            src={sphereball}
                            alt=''
                            key={i}
                            className='sphere-ball'
                            style={{
                                transform:
                                    i === 0 ? 'translate(-28vw, -40vh) scale(1)' ://2
                                        i === 1 ? 'translate(30vw, 38vh)scale(0.8)' :
                                            i === 2 ? 'translate(-25vw, 25vh)scale(0.9)' :
                                                i === 3 ? 'translate(25vw, -20vh) scale(0.7)' :
                                                    i === 4 ? 'translate(0vw, -48vh) scale(2)' :
                                                        i === 5 ? 'translate(-10vw, -10vh) scale(1.4)' ://2
                                                            i === 6 ? 'translate(-5vw, 45vh)' :
                                                                i === 7 && 'translate(35vw, 10vh)scale(1.2)',
                                zIndex: i === 0 ? '2' :
                                    i === 5 && '2',

                                filter: i !== 5 && i !== 0 && 'blur(2px)',

                            }}
                        />)
                    })}

                    <div className="card-comp"
                        onMouseOver={onMouseOver}
                    // onMouseOut={onMouseOut}
                    >
                        <img src={frame1} alt="" className="frame-img" />
                        <div className="bottom-div">
                            <span>
                                <strong>costumer</strong>
                                <br />
                                support
                            </span>
                        </div>
                    </div>

                    <div className="card-comp"
                        onMouseOver={onMouseOver}
                    // onMouseOut={onMouseOut}
                    >
                        <img src={frame2} alt="" className="frame-img" />
                        <div className="bottom-div">
                            <span>
                                <strong>AI</strong>
                                <br />
                                products
                            </span>
                        </div>
                    </div>

                    <div className="card-comp"
                        onMouseOver={onMouseOver}
                    // onMouseOut={onMouseOut}
                    >
                        <img src={frame3} alt="" className="frame-img" />
                        <div className="bottom-div">
                            <span>
                                <strong>Consultancy/</strong>
                                <br />
                                ADvisory
                            </span>
                        </div>
                    </div>


                </div >


            </div >
        </div >
    )
}
