import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

//styles
import './our-new-verticle.scss';

//images
const OurNewVerticleImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/Frame966.png';
const bgLight = 'https://rentblob.blob.core.windows.net/sample-sales/home/bg-light.png';
const HeadingImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/our-ai-product-heading.png';
const TextImg = 'https://rentblob.blob.core.windows.net/sample-sales/home/Leveraging-Generative-AI-Text.png';
const VAContents = 'https://rentblob.blob.core.windows.net/sample-sales/home/virtual-agents-content.png';
const IQAContents = 'https://rentblob.blob.core.windows.net/sample-sales/home/IQA-content.png';
const DOSAContents = 'https://rentblob.blob.core.windows.net/sample-sales/home/DOSA-content.png';




export default function OurNewVerticle({ Progress, PageProgress }) {
    const progress1 = Progress[26]
    const progress2 = Progress[27]
    const progress3 = Progress[28]
    const progress4 = Progress[29]
    const progress5 = Progress[30]


    const [lightOn, setLightOn] = useState(false);

    const [rightContent, setRightContent] = useState(false)

    useMemo(() => {
        if (PageProgress > progress1 && !lightOn) {
            setTimeout(() => {
                setLightOn(true)
            }, 1200)
        } else if (PageProgress < progress1) {
            setLightOn(false)

        }
        // eslint-disable-next-line
    }, [PageProgress])

    useMemo(() => {
        if (PageProgress > progress2 && !rightContent) {
            setTimeout(() => {
                setRightContent(true)
            }, 1200)
        } else if (PageProgress < progress2) {
            setRightContent(false)

        }

        // eslint-disable-next-line 
    }, [PageProgress])

    return (
        <div className="our-new-verticle-main-container"
            style={{
                transform: PageProgress > progress1 && PageProgress < progress5 ? 'translateY(0vh)' :
                    PageProgress > progress5 && 'translateY(-100vh)'
            }}
        >
            {/* <p style={{ position: 'fixed', top: '10%', color: 'red', zIndex: '10' }}>{prgressSection}</p> */}

            <div className="our-new-verticle-main">
                <div className="left-section"
                    style={{
                        width: PageProgress < progress2 && '100%'
                    }}
                >
                    <div className="bg-light-vector"
                        style={{
                            opacity: !lightOn && '0',
                            backgroundImage: `url(${bgLight})`,
                            transform: PageProgress > progress2 && 'scale(0.7)'
                        }}
                    />
                    <img src={OurNewVerticleImg} alt="" className="our-new-verticle-img"
                        style={{
                            transform: PageProgress > progress2 && 'scale(0.7)'

                        }}
                    />

                    <Link
                        to='/how-it-works'
                        className='link-comp'
                        onClick={() => {
                            window.scrollTo(0, 0)
                        }}
                    >Process Details</Link>
                </div>

                <div className="right-section"
                    style={{
                        width: PageProgress < progress2 && '0%'
                    }}
                >
                    <img src={HeadingImg} alt="" className="heading-img"
                        style={{
                            opacity: rightContent && '1'
                        }}
                    />
                    <img src={TextImg} alt="" className="text-img"
                        style={{
                            transform: rightContent && 'translateY(0vh)'
                        }}
                    />

                    <div className="content-slider"
                        style={{
                            transform: rightContent && 'translateY(0vh)'
                        }}
                    >
                        <div className="slider"
                            style={{
                                transform: PageProgress > progress2 && PageProgress < progress3 ? 'translate(0%)' :
                                    PageProgress > progress3 && PageProgress < progress4 ? 'translate(-80%)' :
                                        PageProgress > progress4 && 'translate(-160%)'
                            }}
                        >
                            <img src={VAContents} alt="" className="contents-img" />
                            <img src={IQAContents} alt="" className="contents-img" />
                            <img src={DOSAContents} alt="" className="contents-img" />

                        </div>
                    </div>
                </div>

                {/* --------------------------------------------------- */}
            </div>
        </div>
    )
}
